import styled from "styled-components";

import SliderHome from "./Slider";

import FeaturedProduct from "./components/FeaturedProduct";
import img from "./components/rk__monogram.png";

import ImgCat from "./components/ImgCat";
import MatCat from "./components/MatCat";
import ItalianProduct from "./components/ItalianProducts";

const Home = () => {
  const data = {
    name: "RK Jewells",
  };

  return (
    <>
      {/* <BgImg>
        <img src={img} alt="" />
      </BgImg> */}

      <ImgCat />
      <FeaturedProduct />
      {/* <MatCat /> */}
      <ItalianProduct />
    </>
  );
};

const BgImg = styled.div`
  mix-blend-mode: darken;
  opacity: 0.65;
  margin-left: 25%;
  margin-top: 11rem;
  width: 50%;
  position: fixed;
  z-index: 0;
`;

export default Home;
