import React from "react"
import Sdata from "./Sdata"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"


const SlideCard = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: (dots) => {
      return <ul style={{ margin: "0px" }}>{dots}</ul>
    },
  }
  return (
    <>
      <Slider {...settings}>
        {Sdata.map((value, index) => {
          return (
            <>
        

              <div className='box d_flex top' key={index}>

                  <Simg src={value.cover} alt='' />
              </div>
        
            </>
          )
        })}
      </Slider>
    </>
  )
}


const Backgd = styled.div`
width:150%;
height:100vh;
`
const Simg = styled.img`
margin-top:55.9rem;
width:80%;
heiht:0px;
margin-left:10%;

@media (max-width: ${({ theme }) => theme.media.mobile}) {
  margin-top:40rem;
  width:100%;
  margin-left:0;
}
`

export default SlideCard
