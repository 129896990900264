import React from "react";
import styled from "styled-components";
import Product from "./Product";

const GridView = ({ products }) => {
  return (
    <Wrapper className="section">
      <div className="container grid grid-three-column">
        {products.map((curElem) => {
          return <Product key={curElem.id} {...curElem} />;
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
padding: 9rem 5rem;
margin-top:-10rem;
z-index:1;

.container {
  max-width: 120rem;

}
.intro-data{
  color:#c0924c;
  font-size:25px;
  font-weight:bold;
  display:flex;
  background-color:antiquewhite;
  justify-content:center;
}
.common-heading{
  background-color:antiquewhite;
  color:#c0924c;
  letter-spacing:2rem;
  display:flex;
  justify-content:center;
  ${'' /* font-family:cursive; */}

}

button{
  text-decoration: none;
width: 30rem;
height:5rem;
background-color: ${({theme}) => theme.colors.btn};
color: rgb(255 255 255);
padding: 1.4rem 2.4rem;
margin-left:84%;
margin-top:3rem;
border: none;
border-radius:50px 0px 50px 0px;
text-transform: uppercase;
text-align: center;
cursor: pointer;
transition: all 0.3s ease;
-webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;

&:hover,
&:active {
  box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
  box-shadow: ${({ theme }) => theme.colors.shadowSupport};
  background-color:rgb(255 255 255);
  color: ${({theme}) => theme.colors.btn};
  border: 1px solid  ${({theme}) => theme.colors.btn};
  
  transform: scale(0.96);
}
}

figure {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s linear;
  border-radius:1rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: (255, 254, 254, 0.5);
    transition: all 0.2s linear;
    cursor: pointer;
  }
  &:hover::after {
    width: 100%;
  }
  &:hover img {
    transform: scale(1.3);
  }
  img {
    width: 100%;
    margin-top: 0rem;
    height: 30rem;
    transition: all 0.2s linear;
  }

  .caption {
    position: absolute;
    top: 15%;
    right: 10%;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.bg};
    color: ${({ theme }) => theme.colors.helper};
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    border-radius: 2rem;
  }
}

.card {
  background-color: #fff;
  border: 3px solid gainsboro;
  border-radius: 1rem;
  z-index:0;

  .card-data {
    padding: 0 2rem;
    background-color:smokewhite;
    margin-top:-2rem;
    height:5rem;
  }

  .card-data-flex {
    margin: 2rem 0;
    padding:0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    color: #c28428;
    font-weight:bold;
    font-size:15px;
    text-transform: capitalize;
  }

  .card-data--price {
    color: black;
  }

  .btn {
    margin: 2rem auto;
    background-color: rgb(0 0 0 / 0%);
    border: 0.1rem solid rgb(98 84 243);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgb(98 84 243);
    }

    &:hover a {
      color: #fff;
    }
    a {
      color: rgb(98 84 243);
      font-size: 1.4rem;
    }
  }
}

`;

export default GridView;