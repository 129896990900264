import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiShoppingCart, FiPhoneForwarded,FiShoppingBag, FiHeart,FiHome, FiBookOpen, FiUser  } from "react-icons/fi";
import { CgMenu, CgClose } from "react-icons/cg";
import { useCartContext } from "../context/cart_context";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../styles/Button";

const Nav = () => {
  const [menuIcon, setMenuIcon] = useState();
  const {total_item} = useCartContext();
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const Nav = styled.nav`
    .navbar-lists {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .navbar-link {
        &:link,
        &:visited {
          display: inline-block;
          text-decoration: none;
          font-size: 1.5rem;
          font-weight: 500;
          font-family:italic;
          text-transform: uppercase;
          color: #c0924c;
          padding:3px;
          transition: 0.3s linear;
        }

        &:hover,
        &:active {
        }
        .Icon{
          display:none;
        }
        .tap{
          display:none;
        }
        .log{
          display:flex;
          height:40px;
          width:80px;
          background-color:antiquewhite;
          border:3px solid white;
          font-size:10px;
          justify-content:center;
          color:#c0924c;
          box-shadow:2px 2px 2px #c0924c;

          &:hover{
            background-color:#c0924c;
            border:3px solid antiquewhite;
            padding-top:1.35rem;
            color:antiquewhite;

          }
        }
        .hom{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          font-size:25px;
          margin-left:1.1rem;
        }
        .abo{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          font-size:25px;
          font-weight:100;
          margin-left:1.5rem;
        }
        .pro{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          font-size:25px;
          font-weight:100;
          margin-left:2.9rem;
        }
        .con{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          font-size:25px;
          font-weight:100;
          margin-left:2rem;
        }
        .wis{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          font-size:25px;
          font-weight:100;
          margin-left: 2.5rem;
          color:rgb(228, 63, 90);
          transition: color 0.1s linear;
          &:hover{
            fill: rgb(228, 63, 90);
  
          }
        }
        .car{
          margin-top:0.5rem;
          margin-bottom:0.5rem;
          font-size:25px;
          font-weight:100;
          margin-left:0.5rem;
        }
      }

      .wish-list {
        font-size:35px;
        color:rgb(228, 63, 90);
        transition: color 0.1s linear;
        &:hover{
          fill: rgb(228, 63, 90);
          color: black;

        }
      }
    }

    .mobile-navbar-btn {
      display: none;
      background-color: transparent;
      cursor: pointer;
      border: none;
    }

    .mobile-nav-icon[name="close-outline"] {
      display: none;
    }

    .close-outline {
      display: none;
    }

    .cart-trolley--link {
      position: relative;

      .cart-trolley {
        position: relative;
        font-size: 3.2rem;
      }

      .cart-total--item {
        width: 2.4rem;
        height: 2.4rem;
        margin-top:10px;
        margin-left:-15px;
        position: absolute;
        background-color: #000;
        color: #000;
        border-radius: 50%;
        display: grid;
        place-items: center;
        top: -20%;
        left: 70%;
        background-color: gold;
      }
      
    }

    .user-login--name {
      text-transform: capitalize;
    }

    .user-logout,
    .user-login {
      font-size: 1.4rem;
      padding: 0.8rem 1.4rem;
    }

    @media (max-width: ${({ theme }) => theme.media.mobile}) {
      .mobile-navbar-btn {
        margin-left:30rem;
        margin-top:-1.5rem;
        display: inline-block;
        z-index: 9999;
        border: ${({ theme }) => theme.colors.black};

        .mobile-nav-icon {
          font-size: 4.2rem;
          color: ${({ theme }) => theme.colors.black};
        }
      }

      .active .mobile-nav-icon {
        display: none;
        font-size: 4.2rem;
        position: absolute;
        top: 30%;
        right: 6%;
        color: ${({ theme }) => theme.colors.black};
        z-index: 9999;
      }

      .active .close-outline {
        display: inline-block;
      }

      .navbar-lists {
        margin-top:10rem;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0rem;
        background-color: antiquewhite;
        z-index:6;
        padding-top:15rem;
        padding-left:10rem;
        align-items: start;
        flex-direction: column;

        visibility: hidden;
        opacity: 0;
        transform: translateX(100%);
        /* transform-origin: top; */
        transition: all 3s linear;

        .navbar-link{
          

          .hom{
            position:absolute;
            margin-top:1rem;
            margin-left:-5rem;
            font-size:30px;
          }
          .abo{
            position:absolute;
            margin-top:1rem;
            font-size:30px;
            margin-left:-5rem;
          }
          .pro{
            position:absolute;
            margin-top:1rem;
            font-size:30px;
            margin-left:-5rem;
          }
          .con{
            position:absolute;
            margin-top:1rem;
            font-size:30px;
            margin-left:-5rem;
          }
          .wis{
            position:absolute;
            margin-top:-33rem;
            margin-left:-4rem;
            font-size:35px;
          }
          .car{
            position:absolute;
            margin-top:-35rem;
            margin-left:3rem;
            font-size:35px;
          }

          .Icon{
            display:flex;
            color:#c0924c;
              padding-top:9.5rem;
              position:absolute;
              margin-left:-4rem;
              z-index:2;
              font-size:30px;
          }
          .tap{
            display:flex;
            p{
              color:#c0924c;
              padding-top:8.5rem;
              position:absolute;
              margin-left:13rem;
              z-index:2;

            }
          }
          .log{
            display:flex;
            height:50px;
            width:90%;
            background-color:antiquewhite;
            border:3px solid white;
            position:absolute;
            font-size:25px;
            margin-left:-7.5rem;
            border-radius:10px;
            margin-top:8rem;
            padding-top:2rem;
            color:#c0924c;
            box-shadow:2px 2px 2px #c0924c;
    
            &:hover{
              background-color:#c0924c;
              border:3px solid antiquewhite;
              padding-top:1rem;
              color:antiquewhite;
    
            }
          }
        }
      }
      
      .active .navbar-lists {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        z-index: 999;
        transform-origin: right;
        transition: all 3s linear;

        .navbar-link {
          font-size: 3.2rem;

          .no{
            display:none;
          }
        }
      }
      
      .cart-trolley--link {
        position: relative;

        .cart-trolley {
          position: relative;
          font-size: 5.2rem;
        }

        .cart-total--item {
          margin-left:6rem;
          margin-top:-35.2rem;
          width: 3rem;
          height: 3rem;
          font-size: 2rem;
        }
      }

      .user-logout,
      .user-login {
        font-size: 2.2rem;
        padding: 0.8rem 1.4rem;
      }
    }
  `;

  return (
    <Nav>
      <div className={menuIcon ? "navbar active" : "navbar"}>
        <ul className="navbar-lists">
        
          <li>
            <Link
              to="/"
              className="navbar-link "
              onClick={() => setMenuIcon(false)}>
                <FiHome className="hom"/>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="navbar-link "
              onClick={() => setMenuIcon(false)}>
                <FiBookOpen className="abo"/>
              About
            </Link>
          </li>
          <li>
            <Link
              to="/products"
              className="navbar-link "
              onClick={() => setMenuIcon(false)}>
                <FiShoppingBag className="pro"/>
              Products
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="navbar-link "
              onClick={() => setMenuIcon(false)}>
                <FiPhoneForwarded className="con"/>
              Contact
            </Link>
          </li>
          {isAuthenticated ?  (
              <li>
                <div className="navbar-link">
                  <div className="Icon"><FiUser/></div>
                  <div className="tap"><p>Tap&nbsp;To</p></div>

              <Button  className="log" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log&nbsp;Out
    </Button>
    </div>
              </li>) : (
          <li>
            <div className="navbar-link ">
            <div className="Icon"><FiUser/></div>
            <div className="tap"><p>Tap&nbsp;To</p></div>

              <Button className="log" onClick={() => loginWithRedirect()}>Log&nbsp;In</Button>
              </div>
              </li> ) }
          <li>
            <Link
              to="/wishlist"
              className="navbar-link"
              onClick={() => setMenuIcon(false)}>
              <FiHeart className="wis"/>
              <div className="no">

              Wish-List
              </div>
            </Link>
          </li>
          <li>
            <Link to="/cart" className="navbar-link cart-trolley--link">
              <FiShoppingCart className="car" />
              <span className="cart-total--item"> {total_item} </span>
              <div className="no">

              Cart
              </div>
            </Link>
          </li>
          
        </ul>

        {/* two button for open and close of menu */}
        <div className="mobile-navbar-btn">
          <CgMenu
            name="menu-outline"
            className="mobile-nav-icon"
            onClick={() => setMenuIcon(true)}
          />
          <CgClose
            name="close-outline"
            className="mobile-nav-icon close-outline"
            onClick={() => setMenuIcon(false)}
          />
        </div>
      </div>
    </Nav>
  );
};

export default Nav;