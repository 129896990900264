import React from "react";
import styled from "styled-components";
import { BsFillGridFill, BsList } from "react-icons/bs";
import { useFilterContext } from "../context/filter_context";

const Sort = () => {
  const { filter_products, grid_view, setGridView, setListView, sorting } =
    useFilterContext();
  return (
    <Wrapper className="sort-section">
      {/* 1st column  */}
      <div className="sorting-list--grid">
        <button
          className={grid_view ? "active sort-btn" : "sort-btn"}
          onClick={setGridView}>
          <BsFillGridFill className="icon" />
        </button>

        <button
          className={!grid_view ? "active sort-btn" : " sort-btn"}
          onClick={setListView}>
          <BsList className="icon" />
        </button>
      </div>
      {/* 2nd column  */}
      <div className="product-data">
        <p>{`${filter_products.length} Product Available`}</p>
      </div>

      {/* 3rd column  */}
      <div className="sort-selection">
        <form action="#">
          <label htmlFor="sort"></label>
          <select
            name="sort"
            id="sort"
            className="sort-selection--style"
            onClick={sorting}>
            <option value="lowest">Price(lowest)</option>
            <option value="#" disabled></option>
            <option value="highest">Price(highest)</option>
            <option value="#" disabled></option>
            <option value="a-z">Price(a-z)</option>
            <option value="#" disabled></option>
            <option value="z-a">Price(z-a)</option>
          </select>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  // margin-top: 10rem;
  padding: 10rem;

  .product-data{
    p{

      font-size:30px;
      font-weight:bold;
      margin-left:10rem;
      color: #c28428;

    }
  }

  .sorting-list--grid {
    display: flex;
    gap: 2rem;

    .sort-btn {
      padding: 0.8rem 1rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .icon {
      font-size: 2.6rem;
    }
    .active {
      background-color: #c28428;
      color: #fff;
    }
  }

  .sort-selection .sort-selection--style {
    padding: 0.5rem;
    width:20rem;
    height:5rem;
    padding-left:48px;
    font-size:15px;
    cursor: pointer;

    .sort-select--option {
      padding: 0.5rem 0;
      cursor: pointer;
      height: 2rem;
      padding: 10px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    display: flex;
    justify-content: space-between;
    // margin-top: 10rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 7rem;
    padding-bottom: 7rem;

    .product-data{
      p{
  
        font-size:13px;
        font-weight:bold;
        margin-left:0rem;
      }
    }
  
    .sorting-list--grid {
      display: flex;
      gap: 2rem;
  
      .sort-btn {
        padding: 0rem 1rem;
        height:4rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
  
      .icon {
        font-size: 2rem;
      }
      .active {
        background-color: #c28428;
        color: #fff;
      }
    }
  
    .sort-selection .sort-selection--style {
      padding: 0.5rem;
      width:10rem;
      height:5rem;
      margin-left:0rem;
      padding-left:0px;
      font-size:10px;
      cursor: pointer;
  
      .sort-select--option {
        padding: 0.5rem 0;
        cursor: pointer;
        height: 1rem;
        padding: 10px;
      }
    }

  }
`;

export default Sort;