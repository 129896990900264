import styled from "styled-components";
import img from "./components/rk__monogram.png"
import { FaLongArrowAltRight } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";



const Contact = () => {

  const {isAuthenticated, user} = useAuth0();
  const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:53.7%;
margin-top:14rem;
width:50%;
position:fixed;

`
  const Wrapper = styled.section`
    padding: 0rem 0 5rem 0;
    text-align: center;
    .common-heading{
      color: #c28428;
      padding-top:-5rem;
    }
    .grid{
      .Description{
        margin-left:10rem;
        .ico{
          margin-top:-9.5rem;
          font-size:10rem;
          margin-left:78rem;
        }
        h1{
          font-size:20px;
          color: #c28428;

          span{
            display:none;
          }
        }
        h2{
          margin-top:-20rem;
          color:#c0924c;
          font-size:30px;

        }
        p{
          font-size:20px;
        }
        ul{
          li{
            font-size:20px;
          }
        }
      }
    }
    h2{
      padding-top:15rem;
    }
    .container {
      margin-top: 5rem;
      margin-left:0rem;


      .contact-form {
        max-width: 70rem;
        margin: auto;

        .contact-inputs {
          display: flex;
          z-index:0;
          margin-right:-30rem;          
        margin-left:-5rem;
        margin-top:5rem;
          flex-direction: column;
          gap: 1rem;

          h2{
            margin-top:-25rem;
            color:#c0924c;
            font-size:30px;

          }
          .det{
            z-index:1;
            display:grid;
            margin-top:8.7rem;
            gap:2.3rem;
          }
          .txt{
            z-index:0;

          }
          .em{
            z-index:0;
          }
          .msg{
            z-index:0;
            padding-left:15rem;
            font-size:15px;
            gap:2rem;

          }

          
            p{
              margin-top:-27.3rem;
              font-weight:bold;
              padding-bottom:12rem;
              gap:2rem;
              margin-left:2rem;
              display:flex;
              justify-content:start;
              z-index:4;
              margin-bottom:12rem;
              width:12rem;
            }
          
          .des{
            background-color:white;
            z-index:0;
            padding:10px;
            border:1px solid rgb(78, 144, 205);

            p{
              color:black;
              ${'' /* font-family:cursive; */}

            }
            .fi{
              background-color: ${({ theme }) => theme.colors.white};
              width:100%;
              padding-left: 13rem;
              font-size:15px;
              border:none;
              background:transparent;

            }
          }

          input[type="submit"] {
            margin-top:-11rem;
            cursor: pointer;
            z-index:0;
            transition: all 0.2s;
            background-color:  #c0924c;
            color: rgb(255 255 255);
            padding: 1.4rem 2.4rem;
            font-weight:bold;
            font-family:italic;

            &:hover {
              background-color: rgb(255 255 255);
              border: 1px solid #c0924c;
              color: #c0924c;
              transform: scale(0.9);
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.media.mobile}) {
      .grid{
        .Description{
          margin-left:0rem;
          .ico{
            margin-top:-6rem;
            font-size:3rem;
            margin-left:20.2rem;
            margin-bottom:10rem;
            rotate:90deg;
          }
          h1{
            font-size:15px;
            span{
              display:grid;
            }
          }
          h2{
            padding-left:-20rem;
            font-size:15px;
            
          }
          p{
            marginleft:-10rem;
            font-size:10px;
          }
          ul{
            li{
              font-size:10px;
            }
          }
        }
        .contact-form{
          margin-left:3rem;
          max-width:40rem;

          .contact-inputs {
            display: flex;
            z-index:0;
            margin-right:-30rem;          
          margin-left:-1rem;
          width:40rem;
          margin-top:5rem;
            flex-direction: column;
            gap: 1rem;
  
            h2{
              margin-top:-25rem;
              color:#c0924c;
              font-size:30px;
  
            }
            .det{
              z-index:1;
              display:grid;
              margin-top:5.2rem;
              gap:2.9rem;
            }
          }  
        }
      }
    }
  `;

  const EmptyDiv = styled.div`
  display: grid;
  place-items: center;
  height: 98vh;
  `;

  
  if(!isAuthenticated){
    return(
      <>
      <EmptyDiv>
        <h2>Please Login First</h2>
        </EmptyDiv>
      </>
    )
  }
  
  return (
    <Wrapper>
      <BgImg>
        {/* <img src={img} alt=""/> */}

      </BgImg>
      <h2  className="common-heading">Contact Page</h2>

      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.956236729322!2d80.34708937527859!3d26.45713957692162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c4744af0fd0e5%3A0x6a03754e54991593!2sGhanta%20Ghar%20Chauraha%2C%20Dhana%20Khori%2C%20Mirpur%2C%20Kanpur%2C%20Uttar%20Pradesh%20208001!5e0!3m2!1sen!2sin!4v1698865444381!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{border:0}}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}

      <div className="container">
        <div className="grid grid-two-column">
          <div className="Description">
            <h2>Contact Our Master Jewelers for Exquisite Craftsmanship</h2>
            <p>Welcome to RK Jewells, where the art of jewelry manufacturing meets perfection.
               If you're seeking unparalleled craftsmanship and timeless designs for your Gold jewelry collections like :- </p> 
               <ul type= "disc">
                <li>Open Polki</li>
                <li>Kundan Polki</li>
               <li>Necklace Set</li>
               <li>Pendant Set</li>
               <li> Choker Set</li>
               <li>Full Jadau Set</li>
               <li>Bridal Set, Kada, Nath, Ring</li>
                <li>Or Any Design You Want</li>
               </ul>
               <p>
                Our team of master jewelers is ready to bring your visions to life.<br/> Cause We're here to cater to your desired designs which one do you want to wear once.</p>
                <h1>Send Your Details from Here <span>Below</span> <br/> And We'll Contact You Very Soon.</h1>
                <FaLongArrowAltRight className="ico"/>
          </div>
        <div className="contact-form">
          <form action="https://formspree.io/f/mrgwdbod" method="POST" encType="multipart/form-data" className="contact-inputs">
            <h2>Contact Form</h2>
            <input type="text" value={isAuthenticated ? user.name : ""} className="txt" placeholder="username" name="username" required autoComplete="off"/>
            <input type="email" placeholder="Email" value={isAuthenticated ? user.email : ""} className="em" name="Email" required autoComplete="off"/>
            <textarea name="Message" className="msg" cols="30" rows="7" required autoComplete="off" placeholder="Enter Your Details"></textarea>
            <div className="det">
              <p>Phone :</p>
              <p>State :</p>
              <p>City :</p>
              <p>Your&nbsp;Query :</p>
            </div>
            <input type="submit" value="send"/>
          </form>
        </div>
        </div>
      </div>

    </Wrapper>
  );
};

export default Contact;
