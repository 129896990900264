import { useProductContext } from "../context/productcontex";
import styled from "styled-components";
import Product from "./Product";
import {FiAlertTriangle} from "react-icons/fi"

const Under1000Product = () => {
  const { isLoading, Under1000Price} = useProductContext();
  
            
  if (isLoading) {
    return <Loading> <div className=" loadi"><div className="loadin"></div> <p> Loading </p></div></Loading>;
  }
  if(Under1000Price == 0){
    return <NotAvailable>
      <div className="container">
              <div className="common-heading">Products Under 1000 Rs.</div>

       <div className="notavailable"> <FiAlertTriangle className="ico"/> <p>OOPS! There's No Product Available At This Moment</p></div></div></NotAvailable>
  }

  return (
    <Wrapper className="section">
      <div className="container">
      <div className="common-heading">Under 5000 Rs.</div>
      <div className="product-data">
        <p>{`${Under1000Price.length} Products Available`}</p>
      </div>
        <div className="grid grid-three-column">
          {Under1000Price.map((curElem) => {
            return <Product key={curElem.id} {...curElem} />;
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Loading = styled.div`

.loadi{
  display:flex;
  
  justify-content:center;
  align-items:center;
  place-items:center;

  .loadin{
    border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  margin-top:20rem;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
    }
  p{
    font-size:30px;
    color: #c28428;
    font-weight:bold;
    font-family:Italic;
    margin-top:26rem;
    margin-bottom:5rem;
    margin-left:3rem;
    color:
    }

}
@media (max-width: ${({ theme }) => theme.media.mobile}) {
  .loadin{
    margin-top:-15rem;
  }
  p{
    margin-top:-15rem;
  }
}
`
const NotAvailable = styled.div`
padding-top:3rem;
.common-heading{
  background-color:antiquewhite;
  color:#c0924c;
  letter-spacing:2rem;
  display:flex;
  justify-content:center;
  ${'' /* font-family:cursive; */}

}
.ico{
  font-size:30px;
  color:red;
  background-color:white;
  margin-top:-5rem;
  margin-left:-3rem;
  
}
.notavailable{
  display:flex;
  
  justify-content:center;
  align-items:center;
  place-items:center;
  p{
    font-size:30px;
    color: #c28428;
    font-weight:bold;
    font-family:Italic;
    margin-bottom:5rem;
    margin-left:3rem;
    color:
    }
    }

    @media (max-width: ${({ theme }) => theme.media.mobile}) {
      margin-top:-15rem;

      .ico{
        font-size:20px;
        margin-left:-1rem;
      }
      .notavailable{
        p{
          font-size:15px;
          margin-left:1rem;
        }
      }
    }
`

const Wrapper = styled.section`
padding-top:14rem;
  padding-bottom:9rem;
  padding-left:5rem;
  padding-right:5rem;
margin-top:5rem;
z-index:1;

.container {
  max-width: 120rem;

  .product-data{
    display:flex;
    justify-content:center;
    
    p{
      font-size:30px;
      color:#c28428;
      font-family:italic;
      font-weight:800;
      margin-bottom:5rem;
      margin-top:-5rem;
    }
}
.intro-data{
  color:#c0924c;
  font-size:25px;
  font-weight:bold;
  display:flex;
  background-color:antiquewhite;
  justify-content:center;
}
.common-heading{
  background-color:antiquewhite;
  color:#c0924c;
  letter-spacing:2rem;
  display:flex;
  justify-content:center;
  ${'' /* font-family:cursive; */}

}

button{
  text-decoration: none;
width: 30rem;
height:5rem;
background-color: ${({theme}) => theme.colors.btn};
color: rgb(255 255 255);
padding: 1.4rem 2.4rem;
margin-left:84%;
margin-top:3rem;
border: none;
border-radius:50px 0px 50px 0px;
text-transform: uppercase;
text-align: center;
cursor: pointer;
transition: all 0.3s ease;
-webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;

&:hover,
&:active {
  box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
  box-shadow: ${({ theme }) => theme.colors.shadowSupport};
  background-color:rgb(255 255 255);
  color: ${({theme}) => theme.colors.btn};
  border: 1px solid  ${({theme}) => theme.colors.btn};
  
  transform: scale(0.96);
}
}

figure {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s linear;
  border-radius:1rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: (255, 254, 254, 0.5);
    transition: all 0.2s linear;
    cursor: pointer;
  }
  &:hover::after {
    width: 100%;
  }
  &:hover img {
    transform: scale(1.7);
  }
  img {
    width: 100%;
    margin-top: 0rem;
    height: 30rem;
    transition: all 0.2s linear;
  }

  .caption {
    position: absolute;
    top: 15%;
    right: 10%;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.bg};
    color: ${({ theme }) => theme.colors.helper};
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    border-radius: 2rem;
  }
}
.card {
  background-color: #fff;
  border: 3px solid gainsboro;
  border-radius: 1rem;
  z-index:0;

.card-data {
  padding: 0 2rem;
  background-color:smokewhite;
  margin-top:-2rem;
  height:5.5rem;
}

.card-data-flex {
  margin: 2rem 0;
  padding:1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h3 {
  color: #c28428;
  text-transform: capitalize;
  font-size:15px;
  font-weight:bold;
}

.card-data--price {
  color: black;
}

  .btn {
    margin: 2rem auto;
    background-color: rgb(0 0 0 / 0%);
    border: 0.1rem solid rgb(98 84 243);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgb(98 84 243);
    }

    &:hover a {
      color: #fff;
    }
    a {
      color: rgb(98 84 243);
      font-size: 1.4rem;
    }
  }
}

`;

export default Under1000Product;