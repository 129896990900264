import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Nav from "./Nav"
import img from "./rk_nav_mono.png";
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (

    <MainHeader>
      <div className='mainheader'>
       <Link to="/">
             <Logimg src={img} alt="" className='logimg'/>
         </Link>
       <div className='RK'>
         <h2>|| श्री राधे गोविन्दाय नमः ||</h2>
         </div>
       </div>
       {isAuthenticated && (
      <div className="usr">
        {/* <img src={user.picture} alt={user.name} /> */}
        <h3>Hello!, {user.name}</h3>
        {/* <p>{user.email}</p> */}
      </div>
    )}
       <Nav />
     </MainHeader>
  )
}


const MainHeader = styled.header`
padding: 0 2.8rem;
height: 14.3rem;
background-color:antiquewhite;
width:100%;
display: flex;
justify-content: space-between;
align-items: center;
position: fixed;
z-index:3;
margin-top:-70px; 


h2{
         font-size:22.5px;
         font-weight:bolder;
         margin-left: 40%;
         margin-top:-10rem;
         position:absolute;
         color:#c0924c;
        }
        .usr{
          margin-left:8.5rem;
        h3{
         font-size:22.5px;
         font-weight:bolder;
         padding-left:4.5rem;
         margin-top:4rem;
         font-family:italic;
        position:absolute;
        color:#c28428;
        text-shadow:1px 1px 2px #c0924c;
        letter-spacing:2px;
        }
        }
        @media (max-width: ${({ theme }) => theme.media.mobile}) {
          
          .mainheader{
            padding-top:2rem;
            padding-bottom:2rem;
            padding-left:-5rem;
            margin-left:-12rem;
            .logimg{
              width:13rem;
              margin-left:10rem;
              margin-top:1rem;
              
            }
            .RK{
                        h2{
                          margin-top:-8rem;
                          margin-left:26rem;
                          font-size:15px;
                        }
                      }
                    }
                  }
                 .usr{
                    h3{
                      font-size:15px;
                      padding-left:0rem;
                    }
                  }
                  `;
                  
                  const Logimg = styled.img`
   margin-top:0px;
   width:210px;
   mix-blend-mode: darken;
   opacity:5;


   `


export default Header


