// import { Link } from "react-router-dom";
// import styled from "styled-components";
// import { Button } from "../styles/Button";
// import React, { useContext } from "react";
// import productContext from "../context/productContext";

// const HeroSection = (props) => {
//   const context = useContext(productContext);
//   const {product} = props;

//   return (
//     <Wrapper>
//       <div className="col md-3">
//         <div className="card my-3">
//         {/* <div className="card-body">
//   <div className='d-flex align-items-center'>
//             <figure>
//             <img src={product.image} alt="..." className="card-image-top"/>
//               <h5 className="card-title">{product.title}</h5>
//               <p className="card-text">{product.description}</p>
//             </figure>
//             <Link>
//               <Button>show now</Button>
//             </Link>
//             </div>
//           </div> */}
//           {/* our homepage image  */}
//            <div className="hero-section-image"> 
//             <figure>
//               <img
//                 src="images/RKbg.png"
//                 alt="hero-section-photo"
//                 className="img-style"
//               />
//             </figure>
//             <Link>
//               <Button>show now</Button>
//             </Link>
//           </div>
//         {/*  <div className="hero-section-image"> 
//             <figure>
//               <img
//                 src="images/RKbg.png"
//                 alt="hero-section-photo"
//                 className="img-style"
//               />
//             </figure>
//             <Link>
//               <Button>show now</Button>
//             </Link>
//           </div>
//           <div className="hero-section-image"> 
//             <figure>
//               <img
//                 src="images/RKbg.png"
//                 alt="hero-section-photo"
//                 className="img-style"
//               />
//             </figure>
//             <Link>
//               <Button>show now</Button>
//             </Link>
//           </div>
//           <div className="hero-section-image"> 
//             <figure>
//               <img
//                 src="images/RKbg.png"
//                 alt="hero-section-photo"
//                 className="img-style"
//               />
//             </figure>
//             <Link>
//               <Button>show now</Button>
//             </Link>
//           </div>
//           <div className="hero-section-image"> 
//             <figure>
//               <img
//                 src="images/RKbg.png"
//                 alt="hero-section-photo"
//                 className="img-style"
//               />
//             </figure>
//             <Link>
//               <Button>show now</Button>
//             </Link>
//           </div>
//            */}
          
//         </div>
//       </div>
//     </Wrapper>
//   );
// };

// const Wrapper = styled.section`
//   padding: 12rem 0;


//   .container{
//     margin-top:-72rem;
//   }

//   img {
//     min-width: 10rem;
//     height: 10rem;
//   }

//   .hero-section-data {
//     p {
//       margin: 2rem 0;
//     }

//     h1 {
//       text-transform: capitalize;
//       font-weight: bold;
//     }

//     .intro-data {
//       margin-bottom: 0;
//     }
//   }

//   .hero-section-image {
//     width: 100%;
//     height: auto;
//     display: grid;
//     justify-content: center;
//     align-items: center;
//   }
//   figure {
//     position: relative;

//     &::after {
//       content: "";
//       width: 60%;
//       height: 80%;
//       background-color: gold;
//       position: absolute;
//       left: 50%;
//       top: -5rem;
//       z-index: -1;
//     }
//   }
//   .img-style {
//     width: 100%;
//     height: auto;
//   }

//   @media (max-width: ${({ theme }) => theme.media.mobile}) {
//     .grid {
//       gap: 10rem;
//     }

//     figure::after {
//       content: "";
//       width: 70%;
//       height: 100%;
//       left: 0;
//       top: 10%;
//       /* bottom: 10%; */
//       background-color: rgba(81, 56, 238, 0.4);
//     }
//   }
// `;

// export default HeroSection;



import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../styles/Button";

const HeroSection = ({ myData }) => {
  const { name } = myData;

  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column">
          <div className="hero-section-data">
            <p className="intro-data">Welcome to </p>
            <h1> {name} </h1>
            <p>
            SILVER-As pure as your trust
            </p>
            <NavLink>
              <Button>show now</Button>
            </NavLink>
          </div>
          {/* our homepage image  */}
          <div className="hero-section-image">
            <figure>
              <img
                src="images/test4.jpg"
                alt="hero-section-photo"
                className="img-style"
              />
            </figure>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 12rem 0;

  img {
    min-width: 10rem;
    height: 10rem;
  }

  .hero-section-data {
    p {
      margin: 2rem 0;
    }

    h1 {
      text-transform: capitalize;
      font-weight: bold;
    }

    .intro-data {
      margin-bottom: 0;
    }
  }

  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  figure {
    position: relative;

    &::after {
      content: "";
      width: 60%;
      height: 80%;
      background-color: silver;
      position: absolute;
      left: 50%;
      top: -5rem;
      z-index: -1;
    }
  }
  .img-style {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid {
      gap: 10rem;
    }

    figure::after {
      content: "";
      width: 50%;
      height: 100%;
      left: 0;
      top: 10%;
      /* bottom: 10%; */
      background-color: rgba(81, 56, 238, 0.4);
    }
  }
`;

export default HeroSection;