import { useProductContext } from "../context/productcontex";
import styled from "styled-components";
import Product from "./Product";
import {FiAlertTriangle} from "react-icons/fi"
import { CgSpinner } from "react-icons/cg";


const FeaturedProduct = () => {
  const { isLoading, featureProducts } = useProductContext();
  
            
  if (isLoading) {
    return <Loading> <div className=" loadi"><div className="loadin"></div> <p> Loading </p></div></Loading>;
  }

  if(featureProducts == 0){
    return <NotAvailable>
      <div className="container">
              <div className="common-heading">Featured Products</div>

       <div className="notavailable"> <FiAlertTriangle className="ico"/> <p>OOPS! Check Your Connection & Try Again</p></div></div></NotAvailable>
  }
  return (
    <Wrapper className="section">
      {/* <div className="container"> */}
        <div className="intro-data">Check Now!</div>
        <div className="common-heading">Our&nbsp;Featured<br/>&nbsp;&nbsp;&nbsp;Products</div>
        <div className="grid grid-five-column">
          {featureProducts.map((curElem) => {
            return <Product key={curElem.id} {...curElem} />;
          })}
        {/* </div> */}
      </div>
    </Wrapper>
  );
};
const Loading = styled.div`

.loadi{
  display:flex;
  
  justify-content:center;
  align-items:center;
  place-items:center;

  .loadin{
    border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  margin-top:-60px;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
    }
  p{
    font-size:30px;
    color: #c28428;
    font-weight:bold;
    font-family:Italic;
    margin-top:0px;
    margin-bottom:5rem;
    margin-left:3rem;
    color:
    }

}
@media (max-width: ${({ theme }) => theme.media.mobile}) {
  .loadin{
    margin-top:-15rem;
  }
  p{
    margin-top:-15rem;
  }
}
`
const NotAvailable = styled.div`

.common-heading{
  background-color:antiquewhite;
  color:#c0924c;
  letter-spacing:2rem;
  display:flex;
  justify-content:center;
  ${'' /* font-family:cursive; */}

}
.ico{
  font-size:30px;
  color:red;
  background-color:white;
  margin-top:-5rem;
  margin-left:-3rem;
  
}
.notavailable{
  display:flex;
  
  justify-content:center;
  align-items:center;
  place-items:center;
  p{
    font-size:30px;
    color: #c28428;
    font-weight:bold;
    font-family:Italic;
    margin-bottom:5rem;
    margin-left:3rem;
    color:
    }
    }

    @media (max-width: ${({ theme }) => theme.media.mobile}) {
      margin-top:-15rem;

      .ico{
        font-size:20px;
        margin-left:-1rem;
      }
      .notavailable{
        p{
          font-size:15px;
          margin-left:1rem;
        }
      }
    }
`

const Wrapper = styled.section`
  padding-top:0rem;
  padding-bottom:0rem;
  padding-left:5rem;
  padding-right:5rem;
  margin-top:0rem;
  z-index:1;



  .container {
    max-width: 120rem;

  }
  .common-heading{
    letter-spacing:2rem;
    display:flex;
    justify-content:center;
    ${'' /* font-family:cursive; */}

    background-color:antiquewhite;
    color:#c0924c;
  }
  .intro-data{
    background-color:antiquewhite;
    color:#c28428;
    font-size:25px;
    font-weight:bold;
    display:flex;
    justify-content:center;
  }

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: (255, 254, 254, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.3);
      
    }
    img {
      width: 100%;
      margin-top: 0rem;
      height: 25rem;
      transition: all 0.2s linear;
      border-radius:1rem;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }

  .card {
    background-color: #fff;
    border: 3px solid gainsboro;
    border-radius: 1rem;
    box-shadow:1px 1px 5px black;
    z-index:1;

    .card-data {
      padding: 0 1rem;
      background-color:smokewhite;
      margin-top:-2rem;
      height:5rem;
      border-radius: 1rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      padding:1rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      color: #c28428;
      font-weight:bold;
      font-size:14px;
      text-transform: capitalize;
    }

    .card-data--price {
      color: black;
      font-size:13px;
    }

    .btn {
      margin: 2rem auto;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(98 84 243);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgb(98 84 243);
      }

      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    margin-top:-15rem;
    .common-heading{
      font-size:30px;
      letter-spacing:1rem;
  
    }
    .grid{
      overflow-x:auto;
      display:flex;
      padding-right:5rem;
      padding-left:5rem;
      padding-top:2rem;
      padding-bottom:2rem;
      margin-left:-5rem;
      margin-top:-5rem;
      margin-right:0rem;
      width:121%;
      height:40rem;
    }
    .grid::-webkit-scrollbar{
      width:0;
    }
    figure {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 0.5s linear;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: (255, 254, 254, 0.5);
        transition: all 0.2s linear;
        cursor: pointer;
      }
      &:hover::after {
        width: 100%;
      }
      &:hover img {
        transform: scale(1.5);
        
      }
      img {
        width: 100%;
        margin-top: 0rem;
        height: 20rem;
        transition: all 0.2s linear;
        border-radius:1rem;
      }
  
      .caption {
        position: absolute;
        top: 15%;
        right: 10%;
        text-transform: uppercase;
        background-color: ${({ theme }) => theme.colors.bg};
        color: ${({ theme }) => theme.colors.helper};
        padding: 0.8rem 2rem;
        font-size: 1.2rem;
        border-radius: 2rem;
      }
    }
    
    .card {
      background-color: #fff;
      border: 3px solid gainsboro;
      border-radius: 1rem;
      box-shadow:1px 1px 20px black;
      z-index:0;
  
      .card-data {
        padding: 0 2rem;
        background-color:whitesmoke;
        margin-top:-2rem;
        height:9rem;
        border-radius: 1rem;
      }
  
      .card-data-flex {
        margin: 2rem 0;
        padding:1rem 0;
        font-size:10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
  
      h3 {
        color: #c28428;
        font-weight:bold;
        text-transform: capitalize;
        margin-right:5px;
      }
      p{
        font-size:15px;
        margin-left:10px;
        letter-spacing:-1px;
      }
  }
`;

export default FeaturedProduct;