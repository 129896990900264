import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button } from "../styles/Button";
import { FaDiscord, FaInstagram, FaYoutube, FaWhatsapp} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <Wrapper>
        <section className="contact-short">
          <div className="grid grid-two-column">
            <div>
              <h3>Wanna Get! Your Desired Design Made?</h3>
              <h3>Talk to us today & Personalised Your Design As You Want.</h3>
            </div>

            <div>
              <button className="btn hireme-btn">
                <NavLink to="/contact" className="btn2"><FaWhatsapp className="waico"/><h4>Contact Us</h4></NavLink>
              </button>
            </div>
          </div>
        </section>
        {/* footer section */}

        <footer>
          <div className="container grid grid-four-column">
            <div className="footer-about">
              <h3>RK-Jewells</h3>
              <p>SILVER-As pure as your trust. </p>
            </div>
            <div className="footer-social">
              <h3>Follow Us</h3>
              <div className="footer-social--icons">
                <div>
                  <FaDiscord className="icons" />
                </div>
                <div>
                  <FaInstagram className="icons" />
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UCwfaAHy4zQUb2APNOGXUCCA"
                    target="_blank">
                    <FaYoutube className="icons" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-contact">
              <h3>Call Us</h3>
              <h3>+91 87******10</h3>
            </div>
          </div>

          <div className="footer-bottom--section">
            <hr />
            <div className="container grid grid-two-column ">
              <p>
                @{new Date().getFullYear()} RK-Jewells. All Rights Reserved
              </p>
              <div>
                <p>PRIVACY POLICY</p>
                <p>TERMS & CONDITIONS</p>
              </div>
            </div>
          </div>
        </footer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .iSIFGq {
    margin: 0;
  }

  .contact-short {
    max-width: 60vw;
    height:10vw;
    margin: auto;
    padding: 4rem 5rem;
    background-color: #455a64;
    color:white;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);

    .grid div:first-child{
      h3{
        letter-spacing:0.2rem;
        font-size:17px;
        font-family:italic;
        text-shadow: 1px 1px 1px #4ac959;
      }
    }
    .grid div:last-child {
      justify-self: end;
      align-self: center;
      background-color:antiquewhite;
      border:2px solid white;
      box-shadow:0px 0px 15px white;
      border-radius:10px;
      width:15rem;
      height:5rem;
      padding-top:-2rem;

      .btn2{
        color:#c28428;
        font-size:18px;
        font-family:italic;
        letter-spacing:0.07rem;
        padding-left:20px;

        h4{
          font-size:20px;
          margin-left:4rem;
          margin-top:-3rem;
          font-family:italic;
        letter-spacing:0.07rem;
        }
        .waico{
         margin-top:-1.5rem;
         margin-left:5px;
         font-size:30px; 
        }
      }
    }
  }
  .footer-subscribe {
    z-index:1;
    .em{
      z-index:1;
    }
    .bt {
      z-index:1;
    }
  }

  footer {
    padding: 14rem 0 9rem 0;
    background-color: rgba(6, 0, 0,0.9);
    h3 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
    }
    p {
      color: ${({ theme }) => theme.colors.white};
    }
    .footer-social--icons {
      display: flex;
      gap: 2rem;

      div {
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.white};

        .icons {
          color: ${({ theme }) => theme.colors.white};
          font-size: 2.4rem;
          position: relative;
          cursor: pointer;
        }
      }
    }
  }

  .footer-bottom--section {
    padding-top: 9rem;

    hr {
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.colors.hr};
      height: 0.1px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .contact-short {
      max-width: 80vw;
      height:65vw;
      margin: 2.8rem auto;
      transform: translateY(0%);
      text-align: center;

      .grid div:first-child{
        h3{
          letter-spacing:0.2rem;
          font-size:20px;
          font-family:italic;
          text-shadow: 1px 1px 1px #4ac959;
        }
      }

      .grid div:last-child {
        justify-self: center;
        box-shadow:0px 0px 15px white;

        .btn2{
          color:#c28428;
          font-size:18px;
          font-family:italic;
          letter-spacing:0.07rem;
          padding-left:20px;
  
          h4{
            font-size:15px;
            margin-left:4rem;
            margin-right:5px;
            margin-top:-3rem;
            font-family:italic;
          letter-spacing:0.07rem;
          }
          .waico{
           margin-top:-3rem;
           margin-left:0px;
           font-size:30px; 
          }
        }
      }
    }

    footer {
      padding: 9rem 0 9rem 0;
    }

    .footer-bottom--section {
      padding-top: 4.8rem;
    }
  }
`;

export default Footer;