const ProductReducer = (state, action) => {

  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "SET_API_DATA":
      const featureData = action.payload.filter((curElem) => {
        return curElem.featured === true;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        featureProducts: featureData,
      };

    case "SET_BICHIYA_DATA":
      const bichiyaData = action.payload.filter((curElem) => {
        return curElem.category === "Bichiya";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        BichiyaProducts: bichiyaData,
      };
    case "SET_ALL_DATA":
      const allData = action.payload.filter((curElem) => {
        return curElem;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        allProducts: allData,
      };
    case "SET_PAYAL_DATA":
      const PayalData = action.payload.filter((curElem) => {
        return curElem.category === "Payal";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        PayalProducts: PayalData,
      };
    case "SET_BRACELET_DATA":
      const BraceletData = action.payload.filter((curElem) => {
        return curElem.category === "Bracelet";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        BraceletProducts: BraceletData,
      };
    case "SET_ITALIANCHAIN_DATA":
      const ItalianChainData = action.payload.filter((curElem) => {
        return curElem.category === "ItalianChain";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        ItalianChainProducts: ItalianChainData,
      };
    case "SET_ITALIANPAYAL_DATA":
      const ItalianPayalData = action.payload.filter((curElem) => {
        return curElem.femaleCategory === "ItalianPayal";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        ItalianPayalProducts: ItalianPayalData,
      };
    case "SET_GANESHTEMPLE_DATA":
      const GaneshTempleData = action.payload.filter((curElem) => {
        return curElem.category === "Ganeshji";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        GaneshTempleProducts: GaneshTempleData,
      };
    case "SET_HANUMANTEMPLE_DATA":
      const HanumanTempleData = action.payload.filter((curElem) => {
        return curElem.category === "Hanumanji";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        HanumanTempleProducts: HanumanTempleData,
      };
    case "SET_LAKSHMITEMPLE_DATA":
      const LakshmiTempleData = action.payload.filter((curElem) => {
        return curElem.category === "Laxmiji";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        LakshmiTempleProducts: LakshmiTempleData,
      };
    case "SET_MENS_DATA":
      const MensData = action.payload.filter((curElem) => {
        return curElem.genCategory === "Male";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        MensProducts: MensData,
      };
    case "SET_MENSCHAIN_DATA":
      const MensChainData = action.payload.filter((curElem) => {
        return curElem.maleCategory === "Chain";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        MensChainProducts: MensChainData,
      };
    case "SET_MENSBRACELET_DATA":
      const MensBraceletData = action.payload.filter((curElem) => {
        return curElem.maleCategory === "Bracelet";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        MensBraceletProducts: MensBraceletData,
      };
    
    case "SET_WOMENSCHAIN_DATA":
      const WomensChainData = action.payload.filter((curElem) => {
        return curElem.femaleCategory === "Chain";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        WomensChainProducts: WomensChainData,
      };
    case "SET_WOMENSBRACELET_DATA":
      const WomensBraceletData = action.payload.filter((curElem) => {
        return curElem.femaleCategory === "Bracelet";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        WomensBraceletProducts: WomensBraceletData,
      };
    case "SET_WOMENS_DATA":
      const WomensData = action.payload.filter((curElem) => {
        return curElem.genCategory === "Female";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        WomensProducts: WomensData,
      };
    case "SET_SILVER_DATA":
      const SilverData = action.payload.filter((curElem) => {
        return curElem;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        SilverProducts: SilverData,
      };
    case "SET_GOLD_DATA":
      const GoldData = action.payload.filter((curElem) => {
        return curElem.material === "Gold";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        GoldProducts: GoldData,
      };
    case "SET_ITALIAN_DATA":
      const ItalianData = action.payload.filter((curElem) => {
        return curElem.italian === "";
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        ItalianProducts: ItalianData,
      };
    case "SET_UNDER1000_DATA":
      const Under1000Data = action.payload.filter((curElem) => {
        return curElem.price <= 100000;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        Under1000Price: Under1000Data,
      };
    case "SET_UNDER5000_DATA":
      const Under5000Data = action.payload.filter((curElem) => {
        return curElem.price <= 500000;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        Under5000Price: Under5000Data,
      };
    case "SET_UNDER10000_DATA":
      const Under10000Data = action.payload.filter((curElem) => {
        return curElem.price <= 1000000;
      });

      return {
        ...state,
        isLoading: false,
        products: action.payload,
        Under10000Price: Under10000Data,
      };

    case "API_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case "SET_SINGLE_LOADING":
      return {
        ...state,
        isSingleLoading: true,
      };

    case "SET_SINGLE_PRODUCT":
      return {
        ...state,
        isSingleLoading: false,
        singleProduct: action.payload,
      };

    case "SET_SINGLE_ERROR":
      return {
        ...state,
        isSingleLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default ProductReducer;