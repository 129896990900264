import { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useProductContext } from "./context/productcontex";
import PageNavigation from "./components/PageNavigation";
import MyImage from "./components/MyImage";
import { Container } from "./styles/Container";
import FormatPrice from "./Helpers/FormatPrice";
import { MdSecurity } from "react-icons/md";
import { TbTruckDelivery, TbReplace } from "react-icons/tb";
import Star from "./components/Star";
import AddToCart from "./components/AddToCart";
import AddToWish from "./components/AddToWish";
import DisableBtn from "./styles/DisableBtn";
import { useCartContext } from "./context/cart_context";

const API = "https://midnight-sudden-headlight.glitch.me/api/products";

const SingleProduct = () => {
  const { getSingleProduct, isSingleLoading, singleProduct } =
    useProductContext();


    const { cart, clearCart,total_item, total_price, shipping_fee } = useCartContext();

  const { id } = useParams();

  const {
    id: alias,
    name,
    company,
    price,
    description,
    category,
    stock,
    stars,
    reviews,
    image,
  } = singleProduct;

  useEffect(() => {
    getSingleProduct(`${API}/${id}`);
  }, []);

  if (isSingleLoading) {
    return <div className="page_loading">Loading.....</div>;
  }

  return (
    <Wrapper>
      <div className="Pnav">
      <PageNavigation title={name} />
      </div>
      <Container className="container">
        <div className="grid grid-two-column">
          {/* product Images  */}
          <div className="product_images">
            <MyImage imgs={image} />
          </div>

          {/* product dAta  */}
          <div className="product-data">
            <h2>{name}</h2>
            {/* <Star stars={stars} reviews={reviews} /> */}

            {/* <p className="product-data-price">
              Nu        
              <del>
                <FormatPrice price={price + 250000} />
              </del>
            </p> */}
            <p className="product-data-price product-data-real-price">
              Amount Paid: <FormatPrice price={(shipping_fee + (shipping_fee * (total_item-1))*70/100) + (((total_price) * 3)/100) + total_price} />
            </p>
            <p>{description}</p>
            <div className="product-data-warranty">

              <div className="product-warranty-data">
                <TbReplace className="warranty-icon" />
                <p>3 Days Replacement</p>
              </div>

              <div className="product-warranty-data">
                <TbTruckDelivery className="warranty-icon" />
                <p>ShipRocket Delivery </p>
              </div>

              {/* <div className="product-warranty-data">
                <MdSecurity className="warranty-icon" />
                <p>2 Year Warranty </p>
              </div> */}
            </div>
            {/* <hr /> */}
            {/* {stock > 0 && <AddToCart product={singleProduct} />}
            {stock > 0 && <AddToWish product={singleProduct} />} */}
            {/* <DisableBtn/> */}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
padding-top:0rem;
padding-left:19rem;
.Pnav{
  padding-top:9rem;
  margin-bottom:-9rem;
}
  .container {
    padding: 0rem 0rem 9rem;
    margin-top:10rem;
  }

  .product_images {
    display: flex;
    align-items: center;
  }

  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;

    .product-data-warranty {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;

      .product-warranty-data {
        text-align: center;

        .warranty-icon {
          background-color: rgba(220, 220, 220, 0.5);
          border-radius: 50%;
          width: 4rem;
          height: 4rem;
          padding: 0.6rem;
        }
        p {
          font-size: 1.4rem;
          padding-top: 0.4rem;
        }
      }
    }

    .product-data-price {
      font-weight: bold;
    }
    .product-data-real-price {
      color: ${({ theme }) => theme.colors.btn};
    }
    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.8rem;

      span {
        font-weight: bold;
      }
    }

    hr {
      max-width: 100%;
      width: 90%;
      /* height: 0.2rem; */
      border: 0.1rem solid #000;
      color: red;
    }
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page_loading {
    font-size: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 0rem;
  }
`;

export default SingleProduct; 



// import { useEffect } from "react";
// import styled from "styled-components";
// import { useParams } from "react-router-dom";
// // import { useMyOrderContext } from "./context/myOrder_contex";
// import { useMyOrderContext } from "./context/myOrder_context";
// import PageNavigation from "./components/PageNavigation";
// import MyImage from "./components/MyImage";
// import { Container } from "./styles/Container";
// import FormatPrice from "./Helpers/FormatPrice";
// import { MdSecurity } from "react-icons/md";
// import { TbTruckDelivery, TbReplace } from "react-icons/tb";
// import Star from "./components/Star";
// import AddToCart from "./components/AddToCart";
// import AddToWish from "./components/AddToWish";
// import { useAuth0 } from "@auth0/auth0-react";
// import { CgClose, CgPhone} from "react-icons/cg";
// import { useState } from "react";
// import { Button } from "./styles/Button";

// const API = "http://localhost:5000/api/auth/fetchselected-products/";

// const SingleMyOrder = () => {

//     const [credentials, setCredentials] = useState({name: "", email: "",productId: ""})
//   const [errormsg, setErrormsg] = useState(null);
//   const SaveOrderInfo =async (e) => {
//     e.preventDefault();
//     const {name, email,productId} = credentials;
//     if(credentials.name === '' ||
//        credentials.email === '' ||
//        credentials.productId === ''
//        ){
//         setErrormsg('All fields are Required');
//         return;
//        }else{fetch("http://localhost:5000/api/cancelauth/orders/", {
//         method: "POST", 
//         headers: {
//           "Content-Type": "application/json",
//             },
//             body: JSON.stringify({name, email,productId})
//       })
//                 .then(res => res.json()).then(
//                 data => {
//                   console.log(data);
//                   if(data.error){
//                   setErrormsg(data.error);
//                   }
//                   else{
//                     alert('Your Details Saved Succesfully');
//                     // fetch(handlePayment);
//                   }
//                 }
//               )}
//       }
//       const onChange = (e) => {
//         setCredentials({...credentials, [e.target.name]: e.target.value})
//       }



//     const [show, setShow] = useState();

//   const showLocInp = () => {
//     setShow(true);
//   }
//   const hideLocInp = () => {
//     alert("Come Again Later");
//     setShow(false);
//   }

//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

//   const {isAuthenticated, user} = useAuth0();


//   const handleClick = () => {
//     localStorage.setItem('buttonClickTimestamp', Date.now());
//     setIsButtonDisabled(true);
//   };

//   // Check if button should be disabled
//   const disableButtonAfter3Days = () => {
//     const buttonClickTimestamp = localStorage.getItem('buttonClickTimestamp');
//     if (buttonClickTimestamp) {
//       const threeDaysInMs = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
//       const currentTime = Date.now();
//       if (currentTime - parseInt(buttonClickTimestamp) > threeDaysInMs) {
//         setIsButtonDisabled(true);
//       }
//     }
//   };

//   // Check on component mount
//   useEffect(() => {
//     disableButtonAfter3Days();
//   }, []);


//   const { getSingleMyOrder, isSingleLoading, singleMyOrder } =
//     useMyOrderContext();

//   const { id } = useParams();

//   const {
//     // id: alias,
//     name,
//     price,
//     amount,
//     description,
//     image,
//     date,
//   } = singleMyOrder;

//   useEffect(() => {
//     getSingleMyOrder(`${API}/${id}`);
//   }, []);

//   if (isSingleLoading) {
//     return <div className="page_loading">Loading.....</div>;
//   }

//   return (
//     <Wrapper>
//       <div className="Pnav">
//       <PageNavigation title={name} />
//       </div>
//       <Container className="container">
//         <div className="grid grid-two-column">
//           {/* myOrder Images  */}
//           <div className="myOrder_images">
//             <MyImage imgs={image} />
//           </div>

//           {/* myOrder dAta  */}
//           <div className="myOrder-data">
//             <h2>{name}</h2>
//             {/* <Star stars={stars} reviews={reviews} /> */}

//             <p className="myOrder-data-price">
//               No. Of Items:
//               {amount}
//             </p>
//             <p className="myOrder-data-price myOrder-data-real-price">
//               Amount Paid: <FormatPrice price={price} />
//             </p>
//             <p>{description}</p>
//             <div className="myOrder-data-warranty">
//               {/* <div className="myOrder-warranty-data">
//                 <TbTruckDelivery className="warranty-icon" />
//                 <p>Free Delivery</p>
//               </div> */}

//               <div className="myOrder-warranty-data">
//                 <TbReplace className="warranty-icon" />
//                 <p>3 Days Replacement</p>
//               </div>

//               <div className="myOrder-warranty-data">
//                 <TbTruckDelivery className="warranty-icon" />
//                 <p>ShipRocket Delivery </p>
//               </div>

//               <div className="myOrder-warranty-data">
//                 <MdSecurity className="warranty-icon" />
//                 <p>2 Year Warranty </p>
//               </div>
//             </div>

//             <StyledButton disabled={new Date() - new Date(date) > 24 * 60 * 60 * 1000} onClick={showLocInp}>Cancel Order</StyledButton>
//           {
//             show && (
//               <>
//               <div className="LocInp">
//               {
//           errormsg ? <h3 className="Alrt">{errormsg}</h3> : null
//         }
//               <CgClose className="close" onClick={hideLocInp}/>
//               <form onSubmit={SaveOrderInfo}>
//               {/* <div className="addr"> */}
//               <div className="cont">
//                   <CgPhone className="phone" />
//                   <h2>User's Info</h2>
//                   <p className="fnp">Full Name</p>
//                 <input type="text" className="fn"  onClick={() => setErrormsg(null)}
//               placeholder='Enter Full Name'
//               // onChange={(text) => setCredentials({ ...credentials, name: text})}
//               name="name" onChange={onChange}

//               />
//               <p className="emp">E-mail</p>
//                 <input type="text" className="em" defaultValue={isAuthenticated ? user.email : ""} autoComplete="on" onClick={() => setErrormsg(null)}
//               placeholder='example@gmail.com'
//               // onChange={(text) => setCredentials({ ...credentials, email: text})}
//               name="email" onChange={onChange}

//               />
                
              
//                 {/* <div id="cart-item-id">
//           {products.map((curElem) => {
//             return <CartItem2 key={curElem.id} {...curElem} />;
//           })}<p>{(shipping_fee + (shipping_fee * (total_item-1))*70/100) + ((total_price * 3)/100) + total_price}</p> */}
//           {/* <CgCopy className="copy" onClick={handleCopy}/> */}
//         {/* </div> */}
//         {/* <div className="copyp">
//         <p>* Copy & Paste this Code </p>
//         </div>
//                 <input type="text" id="cp"  onClick={() => setErrormsg(null)}
//               placeholder='Paste Here'
//               // onChange={(text) => setCredentials({ ...credentials, state: text})}
//               name="productId" onChange={onChange}

//               /> */}

//                 </div>
//                 </form>
                
//               <div className="btn">
//               {/* <Button onClick={() => {
//                 SaveOrderInfo();
//               }}>Submit</Button> */}
//               <Button type="submit">Submit</Button>
//               </div>
                
//                 </div>
                
//               </>
//             )
//           }


//                       {/* <StyledButton disabled={new Date() - new Date(date) > 24 * 60 * 60 * 1000} onClick={check}>Return Order</StyledButton> */}

//           </div>
//         </div>
//       </Container>
//     </Wrapper>
//   );
// };

// const StyledButton = styled.button`
//   background-color: ${({ disabled }) => (disabled ? 'lightgray' : '#c0924c')};
//   color: ${({ disabled }) => (disabled ? 'gray' : 'rgb(255 255 255)')};
//   cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
//   text-decoration: none;
//   max-width: auto;
//   ${'' /* background-color:  #c0924c; */}
//   ${'' /* color: rgb(255 255 255); */}
//   padding: 1.4rem 2.4rem;
//   font-weight:bold;
//   font-family:italic;
//   border: none;
//   text-transform: uppercase;
//   text-align: center;
//   ${'' /* cursor: pointer; */}
//   transition: all 0.3s ease;
//   -webkit-transition: all 0.3s ease 0s;
//   -moz-transition: all 0.3s ease 0s;
//   -o-transition: all 0.3s ease 0s;
//   /* Add more styles as needed */

//   &:hover{
//     box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
//     box-shadow: ${({ theme }) => theme.colors.shadowSupport};
//     transform: scale(0.96);
//   }
//   a {
//     text-decoration: none;
//     color: rgb(255 255 255);
//     font-size: 1.8rem;
//   }
// `;

// const Wrapper = styled.section`
// padding-top:0rem;
// padding-left:19rem;
// .Pnav{
//   padding-top:9rem;
//   margin-bottom:-9rem;
// }
//   .container {
//     padding: 0rem 0rem 9rem;
//     margin-top:10rem;
//   }

//   .myOrder_images {
//     display: flex;
//     align-items: center;
//   }

//   .myOrder-data {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: center;
//     gap: 2rem;

//     .myOrder-data-warranty {
//       width: 100%;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       border-bottom: 1px solid #ccc;
//       margin-bottom: 1rem;

//       .myOrder-warranty-data {
//         text-align: center;

//         .warranty-icon {
//           background-color: rgba(220, 220, 220, 0.5);
//           border-radius: 50%;
//           width: 4rem;
//           height: 4rem;
//           padding: 0.6rem;
//         }
//         p {
//           font-size: 1.4rem;
//           padding-top: 0.4rem;
//         }
//       }
//     }

//     .myOrder-data-price {
//       font-weight: bold;
//     }
//     .myOrder-data-real-price {
//       color: ${({ theme }) => theme.colors.btn};
//     }
//     .myOrder-data-info {
//       display: flex;
//       flex-direction: column;
//       gap: 1rem;
//       font-size: 1.8rem;

//       span {
//         font-weight: bold;
//       }
//     }

//     hr {
//       max-width: 100%;
//       width: 90%;
//       /* height: 0.2rem; */
//       border: 0.1rem solid #000;
//       color: red;
//     }
//   }

//   .myOrder-images {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .page_loading {
//     font-size: 3.2rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   @media (max-width: ${({ theme }) => theme.media.mobile}) {
//     padding: 0 0rem;
//   }
// `;

// export default SingleMyOrder; 