import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MatCat = () => {
  return (
    <Wrapper className="section">
    <div className='head'>
      <h2>RK Essentials</h2>
    </div>
    <Link to="/goldproducts" className='pay'>
        <img src="https://cdn.shopify.com/s/files/1/0061/8378/0442/files/AVPD0155_1_360x.jpg?v=1694774331" alt="" />
        <p>Golds</p>
        </Link>
        <Link to="/silverproducts" className='pay2'>
        <img src="https://cdn.shopify.com/s/files/1/0061/8378/0442/files/A0133_2_360x.jpg?v=1696309821" alt="" />
        <p>Silvers</p>
        </Link>
      
      
      
   </Wrapper>
  )
};

const Wrapper = styled.section`
  display:flex;
  position:relative;
  z-index:1;
  margin-bottom:30rem;

  .head{
    h2{
      margin-top:-2rem;
      font-family:italic;
      font-weight:600;
      font-size:70px;
      margin-left:55rem;
      color:#c0924c;

    }
  }
  .pay{
    position:absolute;
margin-top:10rem;
margin-left:12%;
gap:10rem;

img{
  width: 50rem;
  height: 35rem;
  border-radius:20px;
  box-shadow:0px 0px 20px black;
}
p{
  margin-top:-4rem;
  display:flex;
  margin-left:9rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
  .pay2{
    position:absolute;
margin-top:10rem;
margin-left:55%;
gap:10%;


img{
    width: 50rem;
    height: 35rem;
  border-radius:20px;
  box-shadow:0px 0px 20px black;
}
p{
  margin-top:-4rem;
  display:flex;
  margin-left:8.5rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
  .pay3{
    position:absolute;
margin-top:20rem;
margin-left:64%;
gap:10%;

img{
  width: 75%;
  border-radius:20px;
  box-shadow:0px 0px 20px black;
}
p{
  margin-top:-4rem;
  display:flex;
  margin-left:10rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {

    .head{
      h2{
        margin-top:-10rem;
        font-family:italic;
        font-weight:400;
        font-size:40px;
        margin-left:8.5rem;
      }
    }
    .pay{
      margin: -3% 2%;
      width:45%;
      
      img{
        height:20rem;
      }
      p{
        margin-left:7rem;
      }
    }
    .pay2{
      margin-left:53%;
      width:45%;
      margin-top:-3%;

      img{
        height:20rem;
      }
      p{
        margin-left:27%;
      }
    }
  }

  `


export default MatCat
