import { NavLink } from "react-router-dom";
import styled from "styled-components";
import FormatPrice from "../Helpers/FormatPrice";
import { Button } from "../styles/Button";

const ListView = ({ products }) => {
  return (
    <Wrapper className="section">
      <div className="container grid">
        {products.map((curElem) => {
          const { id, name, image, price, description } = curElem;
          return (
            <div className="card grid grid-filter-column">
              <figure>
                <img src={image} alt={name} />
              </figure>

              <div className="card-data">
                <h3>{name}</h3>
                <p>
                  <FormatPrice price={price} />
                </p>
                  <br/>
                <p>{description.slice(0, 46)}...</p>

                <NavLink to={`/singleproduct/${id}`} className="btn-main">
                  <Button className="btn">Read More</Button>
                </NavLink>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 0rem 0;

  .container {
    max-width: 150rem;
  }

  .grid {
    gap: 3.2rem;

    .grid-filter-column{
      grid-template-columns: 0.4fr 1fr;
    }
  }

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.8);
    }
    img {
      max-width: 120%;
      margin-top: 1.5rem;
      height: 30rem;
      transition: all 0.2s linear;
      padding:3rem;
    }
  }

  .card {
    height:40rem;
    border: 0.1rem solid rgb(170 170 170 / 40%);
    margin-left:0rem;

    .card-data {
      position:relative;
      padding: 8rem 20rem;
    }

    h3 {
      margin: 2rem 0;
      font-weight: 300;
      font-size: 2.4rem;
      text-transform: capitalize;
    }

    .btn {
      margin: 2rem 0;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid gold;
      display: flex;
      justify-content: center;
      align-items: center;
      color: gold;

      &:hover {
        background-color: gold;
      }

      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }

    .btn-main .btn:hover {
      color: #fff;
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {

    .container {
      max-width: 150rem;
    }
  
    .grid {
      gap: 3.2rem;
  
      .grid-filter-column{
        grid-template-columns: 0.4fr 1fr;
      }
    }
    figure {
      width: 30rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      transition: all 0.5s linear;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 60%;
        background-color: rgba(255, 255, 255, 0.1);
        transition: all 0.2s linear;
        cursor: pointer;
      }
      &:hover::after {
        width: 100%;
      }
      &:hover img {
        transform: scale(1.8);
      }
      img {
        max-width: 120%;
        margin-top: 0rem;
        height: 30rem;
        transition: all 0.2s linear;
        padding:0rem;
      }
    }
  
    .card {
      height:33.2rem;
      border: 0.1rem solid rgb(170 170 170 / 40%);
      margin-left:-2.5rem;
  
      .card-data {
        position:relative;
        padding: 2rem 1rem;
      }
  
      h3 {
        margin: 2rem 0;
        font-weight: 300;
        font-size: 2.4rem;
        text-transform: capitalize;
      }
  
      .btn {
        margin: 0.5rem 0;
        background-color: rgb(0 0 0 / 0%);
        border: 0.1rem solid gold;
        display: flex;
        width:13rem;
        font-size:10px;
        justify-content: center;
        align-items: center;
        color: gold;
  
        &:hover {
          background-color: gold;
        }
  
        &:hover a {
          color: #fff;
        }
        a {
          color: rgb(98 84 243);
          font-size: 1.4rem;
        }
      }
  
      .btn-main .btn:hover {
        color: #fff;
      }
    }
  }
`;

export default ListView;
