import styled from "styled-components";
import { useWishContext } from "./context/wish_context";
import WishItem from "./components/WishItem";
import { NavLink } from "react-router-dom";
import { Button } from "./styles/Button";
import img from "./components/rk__monogram.png"


const Wish = () => {
  const { wish, clearWish } = useWishContext();

  if (wish.length === 0) {
    return (
      <>
       <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <EmptyDiv>
        <h3>Love It? Add To My Wishlist  </h3>
        <p>My Wishlist allows you to keep track of all of your favorites and shopping activity whether you're 
          on your computer, phone, or tablet. You won't have to waste time searching all over again for that 
          item you loved on your phone the other day - it's all here in one place!</p>
          <NavLink className="Buy"
          to="/products">
            <Button >
Continue Shopping        </Button>
          </NavLink>
      </EmptyDiv>
      </>
    );
  }

  return (
    <>
    <Wrapper>
      <div className="container">
        
        <div className="grid grid-two-column">
          {wish.map((curElem) => {
            return <WishItem key={curElem.id} {...curElem} />;
          })}
        </div>
        <hr />
        <div className="wish-two-button">
          <NavLink to="/products">
            <Button> Want To Wish More </Button>
          </NavLink>
          <Button className="btn btn-clear" onClick={clearWish}>
            clear wishes
          </Button>
        </div>
      </div>
    </Wrapper>
  </>
  );
};

const EmptyDiv = styled.div`
  display: grid;
  place-items: center;
  height: 98vh;

  
  .Buy{
    margin-top:-26rem;
    z-index:1;
  }
  h3 {
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 300;
    margin-bottom:-25rem;
  }
  p {
    padding: 0 30rem;
    font-size: 2rem;
    text-transform: capitalize;
    font-weight: 300;
    margin-top:-5rem;

  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    h3{
      font-size:3.2rem;
      margin-left:0rem;
      margin-top:15rem;
    }
    .Buy{
      margin-top:-40rem;
    }
    p {
      padding: 0 8rem;
      font-size: 1.5rem;
      text-transform: capitalize;
      font-weight: 300;
      margin-top:-5rem;
      margin-bottom:-2rem;
  
    }
  }
`;

const Wrapper = styled.section`
  padding: 9rem 0;

  .header{
    margin-top:4rem;

    h2{
        font-family:monospace;
        display:flex;
        justify-content:center;

    }
    p{
        font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        display:flex;
        justify-content:center;
        font-weight:900;
        font-size:17px;

    }
  }
  
  .grid-two-column {
    margin-top:10rem;
    margin-bottom:5rem;
  }

  
 
  hr {
    margin-top: 1rem;
    background-color:grey;
    height:2px;
    margin-bottom:9rem;
  }
  .wish-item {
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }

  

  .wish-two-button {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .btn-clear {
      background-color: #e74c3c;
    }
  }

  .amount-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    font-size: 1.4rem;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }

  .remove_icon {
    font-size: 1.6rem;
    color: #e74c3c;
    cursor: pointer;
  }

  .order-total--amount {
    width: 100%;
    margin: 4.8rem 0;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .order-total--subdata {
      border: 0.1rem solid #f0f0f0;
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      padding: 3.2rem;
    }
    div {
      display: flex;
      gap: 3.2rem;
      justify-content: space-between;
    }

    div:last-child {
      background-color: #fafafa;
    }

    div p:last-child {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.heading};
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-five-column {
      grid-template-columns: 1.5fr 1fr 0.5fr;
    }
    .wish-hide {
      display: none;
    }

    .wish-two-button {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      gap: 2.2rem;
    }

    .order-total--amount {
      width: 100%;
      text-transform: capitalize;
      justify-content: flex-start;
      align-items: flex-start;

      .order-total--subdata {
        width: 100%;
        border: 0.1rem solid #f0f0f0;
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        padding: 3.2rem;
      }
    }
  }
`;

const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:11rem;
width:50%;
position:fixed;

`

export default Wish;