import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './context/productcontex';
import { FilterContextProvider } from './context/filter_context';
import { CartProvider } from './context/cart_context';
import { WishProvider } from './context/wish_context';
import { Auth0Provider } from '@auth0/auth0-react';
import { MyOrderProvider } from './context/myOrder_context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
    domain="dev-arrbb5sfluvwjtpe.us.auth0.com"
    clientId="KbtKkP41JVqZfvlIg35HMFM7Xp1gJtUF"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <AppProvider>
      <FilterContextProvider>
          <WishProvider>
        <CartProvider>
        <MyOrderProvider>
          <App />
        </MyOrderProvider>
        </CartProvider>
          </WishProvider>
      </FilterContextProvider>
    </AppProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
