import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useFilterContext } from "../context/filter_context";
import { FaArrowDown } from "react-icons/fa";

const CatNav = () => {
  const [menuIcon, setMenuIcon] = useState();

  const Wrapper = styled.section`
  padding: 0rem 0;
  
  display: flex;
  place-items:center;
  
  flex-direction: row;
  gap: 5rem;
  margin-top:-1rem;
  .navbar-lists {
    display: flex;
    gap: 4.8rem;
    align-items: center;
    height:5rem;
    margin-top:-20px;
    
    .navbar-link {
      &:link,
      &:visited {
        display: inline-block;
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: 500;
        text-transform: uppercase;
        color: #c0924c;
        transition: color 0.3s linear;
      }

      .IDown{
        margin-top:-20px;
        margin-left:70px;
        font-size:14px;
      }
      .IDown2{
        margin-top:-20px;
        margin-left:80px;
        font-size:14px;
      }
      .IDown3{
        margin-top:-20px;
        margin-left:35px;
        font-size:14px;
      }
      
      &:hover{
        text-decoration:underline;
        
      }
    }
    
    #italianList{
      display: grid;
      background-color:antiquewhite;
      width:8%;
      height:10rem;
      position:fixed;
      padding-left: 10px;
      padding-top:10px;
      font-size: 15px;
      ${'' /* font-family:cursive; */}

      font-weight:bold;
      margin-left:-2rem;
      color:#c0924c;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      overflow:hidden;
    }
      li:hover ul#italianList{
        opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
        }  
    #genderList{
      display: grid;
      background-color:antiquewhite;
      width:8%;
      height:10rem;
      position:fixed;
      // padding-left: 20px;
      justify-content:center;
      padding-top:10px;
      font-size: 15px;
      ${'' /* font-family:cursive; */}

      font-weight:bold;
      margin-left:-2rem;
      color:#c0924c;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      overflow:hidden;
    }
      li:hover ul#genderList{
        opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
      }  
      #allList{
      display: flex;
      background-color:antiquewhite;
      width:50%;
      gap:10.6rem;
      height:25rem;
      position:fixed;
      padding-left: 50px;
      padding-right: 150px;
      justify-content:space-between;
      padding-top:30px;
      font-size: 15px;
      ${'' /* font-family:cursive; */}

      font-weight:bold;
      margin-left:-23rem;
      color:#c0924c;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      text-shadow:2px 2px 5px white;

      overflow:hidden;
    }
      li:hover ul#allList{
        opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
        }  

        
      
      .Gen{
        font-weight:100;
        font-size:15px;
        display:flex;
        padding-top: 10px;
        justify-content:center;
        
        &:hover{
          text-decoration:underline;
        }
      }
      .pr{
        margin-left:-10px;
        font-weight:100;
        font-size:15px;
        display:flex;
        padding-top: 10px;
        justify-content:center;
        
        &:hover{
          text-decoration:underline;
        }
      }
      
  h3 {
    padding: 2rem 0;
    font-size: bold;
  }

  .filter-search {
    input {
      padding: 0.6rem 1rem;
      width: 80%;
    }
  }

  .filter-category {

    .cat{
      font-size:20px;
      margin-top:10px;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 2rem;
      
      
      
      button {
        // border: none;
        // background-color: ${({ theme }) => theme.colors.white};
        text-transform: capitalize;
        color:rgb(92, 60, 2);
        font-weight:550;
        font-size:20px;
        cursor: pointer;
        
        &:hover {
          color: ${({ theme }) => theme.colors.btn};
        }
      }
      
      
      .hov {
        border-bottom: 1px solid white;
        color: white;
        margin-top:30rem;
        
      }
         
      
    }
  }
  
  .filter-company--select {
    padding: 0.3rem 1.2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
  }

  .filter-color-style {
    display: flex;
    justify-content: center;
  }

  .color-all--style {
    background-color: transparent;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    
    &:hover {
      opacity: 1;
    }
  }
  
  .navbar-link {
    opacity: 1;
  }

  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }

  .filter_price {
    input {
      margin: 0.5rem 0 1rem 0;
      padding: 0;
      box-shadow: none;
      cursor: pointer;
    }
  }

  .filter-shipping {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .filter-clear .btn {
    background-color: #ec7063;
    color: #000;
  }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {

    .navbar-lists{
      .navbar-link{
        .IDown{
        margin-top:-18px;
        margin-left:60px;
        font-size:14px;
      }
      .IDown2{
        margin-top:-18px;
        margin-left:70px;
        font-size:14px;
      }
      .IDown3{
        margin-top:-18px;
        margin-left:25px;
        font-size:14px;
      }
      }
    #italianList{
      display: grid;
      background-color:antiquewhite;
      width:27%;
      height:10rem;
      position:fixed;
      padding-left: 15px;
      padding-top:10px;
      font-size: 13px;
      ${'' /* font-family:cursive; */}

      font-weight:bold;
      margin-left:-2rem;
            color:#c0924c;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      overflow:hidden;
    }
      li:hover ul#italianList{
        opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
        }  
    #genderList{
      display: grid;
      background-color:antiquewhite;
      width:27%;
      height:10rem;
      position:fixed;
      // padding-left: 20px;
      justify-content:center;
      padding-top:10px;
      font-size: 13px;
      ${'' /* font-family:cursive; */}

      font-weight:bold;
      margin-left:-2rem;
            color:#c0924c;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      overflow:hidden;
    }
      li:hover ul#genderList{
        opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
        }  
    #allList{
      display: flex;
      background-color:antiquewhite;
      width:81%;
      gap:2.6rem;
      height:33.8rem;
      position:fixed;
      padding-left: 15px;
      padding-right: 15px;
      justify-content:space-between;
      padding-top:30px;
      font-size: 15px;
      ${'' /* font-family:cursive; */}

      font-weight:bold;
      margin-left:-4rem;
            color:#c0924c;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      overflow:hidden;

    }
      li:hover ul#allList{
          opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
        }  

        .navbar-s{
          font-size:15px;
        text-shadow:2px 2px 10px white;
      }
      
      .Gen{
        font-weight:100;
        font-size:13px;
        display:flex;
        padding-top: 10px;
        justify-content:center;
        
        &:hover{
          text-decoration:underline;
        }

      }
    

      h3 {
    padding: 2rem 0;
    font-size: bold;
  }
    }
    
}
`;

  const {
    filters: { text, category, color },
    updateFilterValue,
    all_products,
  } = useFilterContext();

  // get the unique values of each property
  const getUniqueData = (data, attr) => {
    let newVal = data.map((curElem) => {
      return curElem[attr];
    });

    if (attr === "colors") {
      // return (newVal = ["All", ...new Set([].concat(...newVal))]);
      newVal = newVal.flat();
    }

    return (newVal = ["all", ...new Set(newVal)]);
  };

  // we need to have the individual data of each in an array format
  const categoryData = getUniqueData(all_products, "category");
  const companyData = getUniqueData(all_products, "company");
  const colorsData = getUniqueData(all_products, "colors");
  // console.log(
  //   "🚀 ~ file: FilterSection.js ~ line 23 ~ FilterSection ~ companyData",
  //   colorsData
  // );

  return (
    <>
      <Wrapper>
        <div className="filter-category">
          <div>
            <div className="cat">
              <div className={menuIcon ? "navbar active" : "navbar"}>
                <ul className="navbar-lists" onClick={() => setMenuIcon(false)}>
                  <li>
                    <Link
                      className="navbar-link"
                      onClick={() => setMenuIcon(false)}
                    >
                      All <FaArrowDown className="IDown3" />
                    </Link>
                    <ul id="allList">
                      <li>
                        <Link to="/products" className="navbar-s">
                          All&nbsp;Products
                        </Link>
                      </li>
                      <li>
                        By&nbsp;Gender
                        <hr />
                        <ul>
                          <li>
                            <Link to="/mensProducts" className="Gen">
                              Mens
                            </Link>
                          </li>
                          <li>
                            <Link to="/womensProducts" className="Gen">
                              Womens
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        By&nbsp;Price
                        <hr />
                        <ul>
                          <li>
                            <Link to="/under_1000rs" className="pr">
                              Under&nbsp;1000
                            </Link>
                          </li>
                          <li>
                            <Link to="/under_5000rs" className="pr">
                              Under&nbsp;5000
                            </Link>
                          </li>
                          <li>
                            <Link to="/under_10000rs" className="pr">
                              Under&nbsp;10000
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        Most&nbsp;Seller
                        <hr />
                      </li> */}
                    </ul>
                  </li>

                  <li>
                    <Link
                      className="navbar-link"
                      onClick={() => setMenuIcon(false)}
                    >
                      Italian &nbsp;
                      <FaArrowDown className="IDown" />
                    </Link>
                    <ul id="italianList">
                      <li>
                        <Link to="/italianpayal">Italian&nbsp;Payal</Link>
                      </li>
                      <li>
                        <Link to="/italianchain">Italian&nbsp;Chain</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className="navbar-link"
                      onClick={() => setMenuIcon(false)}
                    >
                      Gender &nbsp;
                      <FaArrowDown className="IDown2" />
                    </Link>
                    <ul id="genderList">
                      <li>
                        <Link to="/mensProducts">Mens</Link>
                      </li>
                      <li>
                        <Link to="/womensProducts">Womens</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Nav = styled.nav`
margin: 2px; 
background-color:whitesmoke;


.space {
  display: flex;
    gap: 4.8rem;
    align-items: center;
    height:10rem;
    justify-content: space-between;
    padding-left:40rem;
    margin-top:2rem;
    


  .navbar-lists {
    display: flex;
    gap: 4.8rem;
    align-items: center;
    height:10rem;

    .navbar-link {
      &:link,
      &:visited {
        display: inline-block;
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: 500;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.black};
        transition: color 0.3s linear;
      }

      &:hover,
      &:navbar-link {
        color: ${({ theme }) => theme.colors.helper};
      }
    }
  
    #allList{
      display: flex;
    gap: 2.8rem;
      background-color:white;
      width:60%;
      height:80rem;
      position:fixed;
      padding: 20px;
      font-size: 15px;
      margin-left:-25rem;
      opacity:0;
      visibility:hidden;
      transition: 0.1s linear;
      overflow:hidden;
    }
      li:hover ul#allList{
          opacity:1;
          visibility:visible;
          border-left:2px solid gold;
          border-bottom:2px solid gold;
          border-right:2px solid gold;
      }  

      .cat-list{
        font-size: 20px;
        font-weight:600;
        ${'' /* font-family:cursive; */}

        padding-top:2rem;
        padding-left:5rem;
        padding-right:5rem;
      }
     
    }

    .nav-list{

      display: flex;
    gap: 2.8rem;
    align-items: center;
    height:10rem;

    
    .search {
      font-size:25px;
    }
    .wish-list {
      font-size:35px;
      color:rgb(228, 63, 90);
      transition: color 0.1s linear;
      &:hover{
        fill: rgb(228, 63, 90);
        color: black;

      }
    }
    


  }
  }

  .mobile-navbar-btn {
    display: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }

  .mobile-nav-icon[name="close-outline"] {
    display: none;
  }

  .close-outline {
    display: none;
  }

  .cart-trolley--link {
    position: relative;

    .cart-trolley {
      position: relative;
      font-size: 3.2rem;
    }

    .cart-total--item {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      background-color: #000;
      color: #000;
      border-radius: 50%;
      display: grid;
      place-items: center;
      top: -20%;
      left: 70%;
      background-color: gold};
    }
  }

  .user-login--name {
    text-transform: capitalize;
  }

  .user-logout,
  .user-login {
    font-size: 1.4rem;
    padding: 0.8rem 1.4rem;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .mobile-navbar-btn {
      display: inline-block;
      z-index: 9999;
      border: ${({ theme }) => theme.colors.black};

      .mobile-nav-icon {
        font-size: 4.2rem;
        color: ${({ theme }) => theme.colors.black};
      }
    }

    .navbar-link .mobile-nav-icon {
      display: none;
      font-size: 4.2rem;
      position: absolute;
      top: 30%;
      right: 10%;
      color: ${({ theme }) => theme.colors.black};
      z-index: 9999;
    }

    .navbar-link .close-outline {
      display: inline-block;
    }

    .navbar-lists {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      visibility: hidden;
      opacity: 0;
      transform: translateX(100%);
      /* transform-origin: top; */
      transition: all 3s linear;
    }

    .navbar-link .navbar-lists {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      z-index: 999;
      transform-origin: right;
      transition: all 3s linear;

      .navbar-link {
        font-size: 4.2rem;
      }
    }
    .cart-trolley--link {
      position: relative;

      .cart-trolley {
        position: relative;
        font-size: 5.2rem;
      }

      .cart-total--item {
        width: 4.2rem;
        height: 4.2rem;
        font-size: 2rem;
      }
    }

    .user-logout,
    .user-login {
      font-size: 2.2rem;
      padding: 0.8rem 1.4rem;
    }
  }
`;
export default CatNav;
