

import { useState } from "react";
import styled from "styled-components";
import {FiHeart} from "react-icons/fi"
import { useWishContext } from "../context/wish_context";

const AddToWish = ({ product }) => {
  const { addToWish } = useWishContext();

  const { id, colors, stock } = product;

  const [color, setColor] = useState(colors[0]);
  const [amount, setAmount] = useState(1);

  const setDecrease = () => {
    amount > 1 ? setAmount(amount - 1) : setAmount(1);
  };

  const setIncrease = () => {
    amount < stock ? setAmount(amount + 1) : setAmount(stock);
  };

     return (
        <Wrapper>
         
      <button onClick={() => addToWish(id, color, amount, product)}>
      <FiHeart className="wish-list"/>
      </button>
        </Wrapper>
      );
    };

const Wrapper = styled.section`
  .colors p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .active {
    opacity: 1;
  }

  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }

  .wish-list {
    font-size:35px;
    margin-top:-6.4rem;
    margin-left:40rem;
    color:rgb(228, 63, 90);
    transition: color 0.1s linear;
    &:hover{
      fill: rgb(228, 63, 90);
      color: black;

    }
  }

  /* we can use it as a global one too  */
  .amount-toggle {
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.4rem;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .wish-list{
      margin-top:-8rem;
    }
  }
`;

export default AddToWish;
