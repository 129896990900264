import React, { useState } from "react";
import FormatPrice from "../Helpers/FormatPrice";
import { FaTrash } from "react-icons/fa";
import { useWishContext } from "../context/wish_context";
import { Link } from "react-router-dom";
import styled from "styled-components";

const WishItem = ({ id, name, image, price }) => {
  const { removeItem } = useWishContext();

  return (
    <>
    <Wrapper>
    <Link to={`/singleproduct/${id}`}>
      <div className="card">
        <figure>
          <img src={image} alt={name} />
        </figure>
      </div>
    </Link>
          <div className="card">
        <div className="card-data-flex">
            <h3>{name}</h3>
            <p className="card-data--price">{<FormatPrice price={price}/>}</p>
            <FaTrash className="remove_icon" onClick={() => removeItem(id)} />
        </div>
          </div>
          

      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  padding: 0rem 0;
  margin-top:0rem;
  z-index:0;

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    border-radius:1rem;
    height:35rem;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: (255, 254, 254, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(3);
    }
    img {
      max-width: 90%;
      margin-top: 1.5rem;
      height: 20rem;
      transition: all 0.2s linear;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: black;
      color: white;
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }


.card {
    background-color: #fff;
    border: 3px solid gainsboro;
    border-radius: 1rem;
    z-index:0;

    .card-data {
      padding: 0 2rem;
    }

    .card-data-flex {
      margin: 2rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
 
    h3 {
      color: ${({ theme }) => theme.colors.text};
      text-transform: capitalize;
    }

    .card-data--price {
      color: ${({ theme }) => theme.colors.helper};
    }

    .btn {
      margin: 2rem auto;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(98 84 243);
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgb(98 84 243);
      }

      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }
  }
  `

export default WishItem;
