const wishReducer = (state, action) => {
  if (action.type === "ADD_TO_WISH") {
    let { id, color, amount, product } = action.payload;

    // tackle the existing product

    // let existingProduct = state.wish.find(
    //   (curItem) => curItem.id === id + color
    // );

    // if (existingProduct) {
    //   let updatedProduct = state.wish.map((curElem) => {
    //     if (curElem.id === id + color) {
    //       let newAmount = curElem.amount + amount;

    //       if (newAmount >= curElem.max) {
    //         newAmount = curElem.max;
    //       }
    //       return {
    //         ...curElem,
    //         amount: newAmount,
    //       };
    //     } else {
    //       return curElem;
    //     }
    //   });
    //   return {
    //     ...state,
    //     wish: updatedProduct,
    //   };
    // } else {
      let wishProduct = {
        id: id + color,
        name: product.name,
        color,
        amount,
        image: product.image[0].url,
        price: product.price,
        max: product.stock,
      };

      return {
        ...state,
        wish: [...state.wish, wishProduct],
      };
    // }
  }

  // to set the increment and decrement
  if (action.type === "SET_DECREMENT") {
    let updatedProduct = state.wish.map((curElem) => {
      if (curElem.id === action.payload) {
        let decAmount = curElem.amount - 1;

        if (decAmount <= 1) {
          decAmount = 1;
        }

        return {
          ...curElem,
          amount: decAmount,
        };
      } else {
        return curElem;
      }
    });
    return { ...state, wish: updatedProduct };
  }

  if (action.type === "SET_INCREMENT") {
    let updatedProduct = state.wish.map((curElem) => {
      if (curElem.id === action.payload) {
        let incAmount = curElem.amount + 1;

        if (incAmount >= curElem.max) {
          incAmount = curElem.max;
        }

        return {
          ...curElem,
          amount: incAmount,
        };
      } else {
        return curElem;
      }
    });
    return { ...state, wish: updatedProduct };
  }

  if (action.type === "REMOVE_ITEM") {
    let updatedWish = state.wish.filter(
      (curItem) => curItem.id !== action.payload
    );
    return {
      ...state,
      wish: updatedWish,
    };
  }

  // to empty or to clear to wish
  if (action.type === "CLEAR_WISH") {
    return {
      ...state,
      wish: [],
    };
  }

  // if (action.type === "WISH_TOTAL_ITEM") {
  //   let updatedItemVal = state.wish.reduce((initialVal, curElem) => {
  //     let { amount } = curElem;

  //     initialVal = initialVal + amount;
  //     return initialVal;
  //   }, 0);

  //   return {
  //     ...state,
  //     total_item: updatedItemVal,
  //   };
  // }

  // if (action.type === "WISH_TOTAL_PRICE") {
  //   let total_price = state.wish.reduce((initialVal, curElem) => {
  //     let { price, amount } = curElem;

  //     initialVal = initialVal + price * amount;

  //     return initialVal;
  //   }, 0);

  //   return {
  //     ...state,
  //     total_price,
  //   };
  // }

  if (action.type === "WISH_ITEM_PRICE_TOTAL") {
    let { total_item, total_price } = state.wish.reduce(
      (accum, curElem) => {
        let { price, amount } = curElem;

        accum.total_item += amount;
        accum.total_price += price * amount;

        return accum;
      },
      {
        total_item: 0,
        total_price: 0,
      }
    );
    return {
      ...state,
      total_item,
      total_price,
    };
  }

  return state;
};

export default wishReducer;

// https://stackoverflow.com/questions/63117470/how-to-return-two-values-in-reduce#:~:text=You%20cannot%20return%20two%20values%20in%20reduce%20.



// const wishReducer = (state, action) => {
//     if (action.type === "ADD_TO_WISH") {
//       let { id, color, amount, name, image, price, stock, product } = action.payload;

//     // tackle the existing product

//     // let existingProduct = state.wish.find(
//     //   (curItem) => curItem.id === id + color
//     // );

//     // if (existingProduct) {
//     //   let updatedProduct = state.wish.map((curElem) => {
//     //     if (curElem.id === id + color) {
//     //       let newAmount = curElem.amount + amount;

//     //       if (newAmount >= curElem.max) {
//     //         newAmount = curElem.max;
//     //       }
//     //       return {
//     //         ...curElem,
//     //         amount: newAmount,
//     //       };
//     //     } else {
//     //       return curElem;
//     //     }
//     //   });
//     //   return {
//     //     ...state,
//     //     wish: updatedProduct,
//     //   };
//     // } else {
//       let wishProduct = {
//         id: id + color,
//         name : product.name,
//         color,
//         amount,
//         image: product.image[0].url,
//         price: product.price,
//         max: product.stock,
//       };

//       return {
//         ...state,
//         wish: [...state.wish, wishProduct],
//       };
//     // }
//   }
//     //   let { id, color, amount, product } = action.payload;
//     //     let wishProduct = {
//     //       id: id + color,
//     //       name: product.name,
//     //       color,
//     //       amount,
//     //       image: product.image[0].url,
//     //       price: product.price,
//     //       max: product.stock,
//     //     };
  
//     //     return {
//     //       ...state,
//     //       wish: [...state.wish, wishProduct],
//     //     };
//     // //   }
//     // }
  
//     // to set the increment and decrement
//     if (action.type === "SET_DECREMENT") {
//       let updatedProduct = state.wish.map((curElem) => {
//         if (curElem.id === action.payload) {
//           let decAmount = curElem.amount - 1;
  
//           if (decAmount <= 1) {
//             decAmount = 1;
//           }
  
//           return {
//             ...curElem,
//             amount: decAmount,
//           };
//         } else {
//           return curElem;
//         }
//       });
//       return { ...state, wish: updatedProduct };
//     }
  
//     if (action.type === "SET_INCREMENT") {
//       let updatedProduct = state.wish.map((curElem) => {
//         if (curElem.id === action.payload) {
//           let incAmount = curElem.amount + 1;
  
//           if (incAmount >= curElem.max) {
//             incAmount = curElem.max;
//           }
  
//           return {
//             ...curElem,
//             amount: incAmount,
//           };
//         } else {
//           return curElem;
//         }
//       });
//       return { ...state, wish: updatedProduct };
//     }
  
//     if (action.type === "REMOVE_ITEM") {
//       let updatedWish = state.wish.filter(
//         (curItem) => curItem.id !== action.payload
//       );
//       return {
//         ...state,
//         wish: updatedWish,
//       };
//     }
  
//     // to empty or to clear to wish
//     if (action.type === "CLEAR_WISH") {
//       return {
//         ...state,
//         wish: [],
//       };
//     }
  
//     return state;
//   };
  
//   export default wishReducer;
  
//   // https://stackoverflow.com/questions/63117470/how-to-return-two-values-in-reduce#:~:text=You%20cannot%20return%20two%20values%20in%20reduce%20.
  