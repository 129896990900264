import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ImgCat = () => {
  return (
    <Wrapper className="section">
    
    <div className='pok'>
    {/* <Link to="/bichiya" className='pay'>
        <img src="https://cdn.shopify.com/s/files/1/0061/8378/0442/files/PD0672_1_360x.jpg?v=1698329460" alt="" />
        <p>Bichiya</p>
        </Link> */}
        <Link to="/bracelet" className='pay2'>
        {/* <img src="https://i.ibb.co/r5JpS3P/Remove-background-project.png" alt="" />
        <p>Bracelet</p> */}
        <div className="card">
        <figure>
          <img src="https://i.ibb.co/r5JpS3P/Remove-background-project.png" alt=""/>
          {/* <figcaption className="caption">{category}</figcaption> */}
        </figure>

        <div className="card-data">
          <div className="card-data-flex">
            <h3>Bracelet</h3>
            {/* <p className="card-data--price">{<FormatPrice price={price}/>}</p> */}
          </div>
        </div>
      </div>
        </Link>
        <Link to="/payal" className='pay3'>
        {/* <img src="https://i.ibb.co/yk2zhfc/Remove-background-project-3.png" alt="" />
        <p>Payal</p> */}
        <div className="card">
        <figure>
          <img src="https://i.ibb.co/yk2zhfc/Remove-background-project-3.png" alt=""/>
          {/* <figcaption className="caption">{category}</figcaption> */}
        </figure>

        <div className="card-data">
          <div className="card-data-flex">
            <h3>Payal</h3>
            {/* <p className="card-data--price">{<FormatPrice price={price}/>}</p> */}
          </div>
        </div>
      </div>
        </Link>
        </div>
      
      
      
      
   </Wrapper>
  )
};

const Wrapper = styled.section`
  display:flex;
  position:relative;
  z-index:1;
  margin-bottom:50rem;

  .pay{
    position:absolute;
margin-top:20rem;
margin-left:22.9%;
gap:10%;

img{
  width: 75%;
  border-radius:20px;
  box-shadow:0px 0px 10px black;
}
p{
  margin-top:-4rem;
  display:flex;
  margin-left:9rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
  .pay2{
    position:absolute;
margin-top:15rem;
margin-left:27%;
gap:10%;


${'' /* img{
  width: 80%;
  height:28rem;
  border-radius:20px;
  box-shadow:0px 0px 10px black;
} */}

  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: (255, 254, 254, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.3);
      
    }
    img {
      width: 30rem;
      margin-top: 0rem;
      height: 25rem;
      transition: all 0.2s linear;
      border-radius:1rem;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }

  .card {
    background-color: #fff;
    border: 3px solid gainsboro;
    border-radius: 1rem;
    box-shadow:1px 1px 5px black;
    z-index:1;

    .card-data {
      padding: 0 1rem;
      background-color:smokewhite;
      margin-top:-2rem;
      height:5rem;
      border-radius: 1rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      padding:1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: #c28428;
      font-weight:bold;
      text-transform: uppercase;
    }

    .card-data--price {
      color: black;
      font-size:13px;
    }

p{
  margin-top:-4rem;
  display:flex;
  margin-left:8.5rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
  }
  .pay3{
    position:absolute;
margin-top:15rem;
margin-left:52%;
gap:10%;

${'' /* img{
  width: 30rem;
  height:28rem;
  border-radius:20px;
  box-shadow:0px 0px 10px black;
} */}
figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: (255, 254, 254, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.3);
      
    }
    img {
      width: 30rem;
      margin-top: 0rem;
      height: 25rem;
      transition: all 0.2s linear;
      border-radius:1rem;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }

  .card {
    background-color: #fff;
    border: 3px solid gainsboro;
    border-radius: 1rem;
    box-shadow:1px 1px 5px black;
    z-index:1;

    .card-data {
      padding: 0 1rem;
      background-color:smokewhite;
      margin-top:-2rem;
      height:5rem;
      border-radius: 1rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      padding:1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: #c28428;
      font-weight:bold;
      text-transform: uppercase;
    }

    .card-data--price {
      color: black;
      font-size:13px;
    }
p{
  margin-top:-4rem;
  display:flex;
  margin-left:10rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
}

@media (max-width: ${({ theme }) => theme.media.mobile}) {

  .pok{
    margin-left:-3rem;

    .pay{
      margin-left:7rem;
      img{
        width:13rem;
        height:auto;
      }
      p{
        font-size:15px;
        margin-left:4.2rem;
        margin-top:-3rem;
      }
    }
    .pay2{
      margin-left:11rem;
      
  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: (255, 254, 254, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.3);
      
    }
    img {
      width: 16rem;
      margin-top: 0rem;
      height: auto;
      transition: all 0.2s linear;
      border-radius:1rem;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }

  .card {
    background-color: #fff;
    border: 3px solid gainsboro;
    border-radius: 1rem;
    box-shadow:1px 1px 5px black;
    z-index:1;

    .card-data {
      padding: 0 1rem;
      background-color:smokewhite;
      margin-top:-2rem;
      height:5rem;
      border-radius: 1rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      padding:1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: #c28428;
      font-weight:bold;
      text-transform: uppercase;
    }

    .card-data--price {
      color: black;
      font-size:13px;
    }

p{
  margin-top:-4rem;
  display:flex;
  margin-left:8.5rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
    }
    .pay3{
      margin-left:60%;
      figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: (255, 254, 254, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.3);
      
    }
    img {
      width: 16rem;
      margin-top: 0rem;
      height: 12rem;
      transition: all 0.2s linear;
      border-radius:1rem;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }
  }

  .card {
    background-color: #fff;
    border: 3px solid gainsboro;
    border-radius: 1rem;
    box-shadow:1px 1px 5px black;
    z-index:1;

    .card-data {
      padding: 0 1rem;
      background-color:smokewhite;
      margin-top:-2rem;
      height:5rem;
      border-radius: 1rem;
    }

    .card-data-flex {
      margin: 2rem 0;
      padding:1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: #c28428;
      font-weight:bold;
      text-transform: uppercase;
    }

    .card-data--price {
      color: black;
      font-size:13px;
    }
p{
  margin-top:-4rem;
  display:flex;
  margin-left:10rem;
  font-size:25px;
  font-weight:bold;
  color:black;
  text-shadow:2px 2px 1px silver;
}
  }
  }

  }
  `

export default ImgCat
