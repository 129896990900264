import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./Home"
import About from "./About";
import Products from "./Products";
import Contact from "./Contact";
import Cart from "./Cart";
import ErrorPage from "./ErrorPage";
import SingleProduct from "./SingleProduct";
import { GlobalStyle } from "./GlobalStyle";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styled from "styled-components";
import WishList from "./WishList";
import Bichiya from "./Bichiya";
// import AllProducts from "./AllProducts";
import ProductNav from "./components/ProductNav";
import Payal from "./Payal";
import Bracelet from "./Bracelet";
import ItalianPayal from "./ItalianPayal";
import ItalianChain from "./ItalianChain";
import Ganesh from "./Ganesh";
import Lakshmi from "./Lakshmi";
import Hanuman from "./Hanuman";
import Mens from "./Mens";
import Womens from "./Womens";
import Gold from "./Gold";
// import Caters from "./caters";
import Silver from "./SIlver";
import Italian from "./Italian";
import Under1000 from "./Under1000";
import Under10000 from "./Under10000";
import Under5000 from "./Under5000";
import MensChain from "./MensChain";
import MensBracelet from "./MensBracelet";
import WomensChain from "./WomensChain";
import WomensBracelet from "./WomensBracelet";
import MyOrders from "./MyOrders";
import SingleMyOrder from "./SingleMyOrder";
import CancelOrder from "./Cancel";
import Return from "./Return";
import Cancel from "./Cancel";



const App = () => {
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "ghostwhite",
      footer_bg: " linear-gradient(to bottom, #273909, #223707, #1d3505, #173304, #113102);",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "1024px",
      tab: "998px",
    },
  }
  return(
    
  <ThemeProvider theme = {theme}>
  <Router>
    <GlobalStyle/>
    <Routes>

    </Routes>
    <NavGap>
    <Header/>
    <div className="Pnav">

    <ProductNav/>
    </div>
    
    </NavGap>
    <Backg>
    
        

      
    <Routes>
    <Route path="/products" element={<Products/>}/>
    <Route path="/" element={<Home/>}  />
    <Route path="/about" element={<About/>}/>
    <Route path="/bichiya" element={<Bichiya/>}/>
    <Route path="/payal" element={<Payal/>}/>
    <Route path="/bracelet" element={<Bracelet/>}/>
    <Route path="/italianpayal" element={<ItalianPayal/>}/>
    <Route path="/italianchain" element={<ItalianChain/>}/>
    <Route path="/ganeshtemple" element={<Ganesh/>}/>
    <Route path="/lakshmitemple" element={<Lakshmi/>}/>
    <Route path="/hanumantemple" element={<Hanuman/>}/>
    <Route path="/mensProducts" element={<Mens/>}/>
    <Route path="/mensChainProducts" element={<MensChain/>}/>
    <Route path="/mensBraceletProducts" element={<MensBracelet/>}/>
    <Route path="/womensChainProducts" element={<WomensChain/>}/>
    <Route path="/womensBraceletProducts" element={<WomensBracelet/>}/>
    <Route path="/womensProducts" element={<Womens/>}/>
    {/* <Route path="/silverproducts" element={<AllProducts/>}/> */}
    <Route path="/italianproducts" element={<Italian/>}/>
    <Route path="/goldproducts" element={<Gold/>}/>
    {/* <Route path="/caters" element={<Caters/>}/> */}
    <Route path="/silverproducts" element={<Silver/>}/>
    <Route path="/under_1000rs" element={<Under1000/>}/>
    <Route path="/under_5000rs" element={<Under5000/>}/>
    <Route path="/under_10000rs" element={<Under10000/>}/>
    <Route path="/wishlist" element={<WishList/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/singleproduct/:id" element={<SingleProduct/>}/>
    <Route path="/singlemyorder/:id" element={<SingleMyOrder/>}/>
    <Route path="/cart" element={<Cart/>}/>
    <Route path="/cancelorder" element={<Cancel/>}/>
    <Route path="/returnorder" element={<Return/>}/>
    <Route path="/myorders" element={<MyOrders/>}/>
    <Route path="*" element={<ErrorPage/>}/>

    </Routes>
    </Backg>
    <Footer/>
  </Router>
  </ThemeProvider>
  
  )
};

const Backg = styled.div`

background-color:antiquewhite;
`


const NavGap = styled.div`
margin-top:6rem;

@media (max-width: ${({ theme }) => theme.media.mobile}) {

  .Pnav{
    ProductNav{
      margin-top:-1.8rem;
    }
  }

}
`


export default App;
