import React from 'react'
import HeroSection from "./components/HeroSection";
import styled from 'styled-components';
import {  useProductContext } from './context/productcontex';
import img from "./components/rk__monogram.png"

const About = () => {
  const {myName} = useProductContext;
    const data = {
        name: "RK Silvers",
      };
  return (
    <>
    {myName}
    <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <HeroSection myData={data} />
    </>
  )
}
const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:10rem;
width:50%;
position:fixed;

`
export default About;
