import styled from "styled-components";
import { useFilterContext } from "../context/filter_context";
import { FaSearch } from "react-icons/fa";

const FilterSection = () => {
  const {
    filters: { text },
    updateFilterValue,
  } = useFilterContext();


  return (
    <Wrapper>
      <div className="filter-search">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
          
            type="text"
            name="text"
            placeholder="Search Your Favourite One"
            value={text}
            onChange={updateFilterValue}
          />
        </form>
        <FaSearch className="fa-search"/>
      </div>



      
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top:0rem;

  .fa-search {
    margin-top:10.6rem;
    position:fixed;
    font-size:20px;
    color:#c28428;
    margin-left:33.8%;
    z-index:1;
  }
  h3 {
    padding: 2rem 0;
    font-size: bold;
  }

  .filter-search {
    input {
      margin-top: 10rem;
      margin-left:32.7%;
      width: 70rem;
      position:fixed;
      border-radius:20px 20px 0px 0px;
      border:1px solid #c28428;
      font-size:15px;
      padding-left:5rem;
      z-index:1;
      height:1rem;
    }
  }
  

  .filter-category {
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.4rem;

      button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          color: ${({ theme }) => theme.colors.btn};
        }
      }

      .active {
        border-bottom: 1px solid #000;
        color: ${({ theme }) => theme.colors.btn};
      }
    }
  }

  .filter-company--select {
    padding: 0.3rem 1.2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
  }

  .filter-color-style {
    display: flex;
    justify-content: center;
  }

  .color-all--style {
    background-color: transparent;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .active {
    opacity: 1;
  }

  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }

  .filter_price {
    input {
      margin: 0.5rem 0 1rem 0;
      padding: 0;
      box-shadow: none;
      cursor: pointer;
    }
  }

  .filter-shipping {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .filter-clear .btn {
    background-color: #ec7063;
    color: #000;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {

    .fa-search {
      margin-top:8.6rem;
      position:fixed;
      font-size:15px;
      color:#c28428;
      margin-left:24%;
      z-index:1;
    }
    h3 {
      padding: 2rem 0;
      font-size: bold;
    }
  
    .filter-search {
      input {
        margin-top: 7.8rem;
        margin-left:20%;
        width: 60%;
        position:fixed;
        border-radius:20px 20px 0px 0px;
        border:1px solid #c28428;
        font-size:11px;
        padding-left:5rem;
        z-index:1;
        height:1rem;
      }
    }
  }
`;

export default FilterSection;