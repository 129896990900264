import { NavLink } from "react-router-dom";
import styled from "styled-components";
import FormatPrice from "./Helpers/FormatPrice";
import { Button } from "./styles/Button";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CgClose, CgPhone } from "react-icons/cg";
import CartItem2 from "./components/CartItem2";
import { useCartContext } from "./context/cart_context";

const MyOrder = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const [progress, setProgress] = useState(0);

  const handleButtonClick = () => {
    // Simulate progress increment
    if (progress < 100) {
      setProgress(progress + 25);
    }
  };

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    productId: "",
  });
  const [errormsg, setErrormsg] = useState(null);
  const SaveOrderInfo = async (e) => {
    e.preventDefault();
    const { name, email, productId } = credentials;
    if (
      credentials.name === "" ||
      credentials.email === "" ||
      credentials.productId === ""
    ) {
      setErrormsg("All fields are Required");
      return;
    } else {
      fetch("http://localhost:5000/api/cancelauth/orders/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, productId }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.error) {
            setErrormsg(data.error);
          } else {
            alert("Your Details Saved Succesfully");
          }
        });
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const { cart, clearCart, total_item, total_price, shipping_fee } =
    useCartContext();

  const [show, setShow] = useState();

  const showLocInp = () => {
    setShow(true);
  };
  const hideLocInp = () => {
    alert("Come Again Later");
    setShow(false);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { isAuthenticated, user } = useAuth0();

  const handleClick = () => {
    localStorage.setItem("buttonClickTimestamp", Date.now());
    setIsButtonDisabled(true);
  };

  const disableButtonAfter3Days = () => {
    const buttonClickTimestamp = localStorage.getItem("buttonClickTimestamp");
    if (buttonClickTimestamp) {
      const threeDaysInMs = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
      const currentTime = Date.now();
      if (currentTime - parseInt(buttonClickTimestamp) > threeDaysInMs) {
        setIsButtonDisabled(true);
      }
    }
  };

  useEffect(() => {
    disableButtonAfter3Days();
  }, []);

  const check = () => {
    console.log("checking");
  };

  const [products, setProducts] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `http://localhost:5000/api/auth/fetchselectedproducts/${password}`
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  };
  return (
    <Wrapper className="section">
      <div className="container grid">
        <div className="mb">
          <div className="LocInp">
            <form onSubmit={handleSubmit}>
              <div className="cont">
                <h3>Enter Your Ordered Mail</h3>
                <input
                  type="email"
                  className="ep"
                  placeholder="Enter Mail"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="btn">
                <Btn type="submit">Submit</Btn>
              </div>
            </form>
          </div>
        </div>

        {products.map((curElem) => {
          const { id, name, image, price, amount, description, date, status,link } = curElem;
          return (
            <div className="card grid grid-filter-column">
              <figure>
                <img src={image} alt={name} />
              </figure>

              <div className="card-data">
                <h3>{name}</h3>
                <p>
                  <FormatPrice
                    price={
                      shipping_fee +
                      (shipping_fee * (total_item - 1) * 70) / 100 +
                      (price * amount * 3) / 100 +
                      price * amount
                    }
                  />
                </p>
                <br />
                <p>{description}...</p>
                <NavLink to={`/singleproduct/${id}`} className="btn-main">
                  <Button className="btn">Buy Again</Button>
                </NavLink>
                <div className="btn2">
                  <NavLink to="/cancelorder">
                    <StyledButton
                      disabled={
                        new Date() - new Date(date) > 24 * 60 * 60 * 1000
                      }
                    >
                      Cancel Order
                    </StyledButton>
                  </NavLink>
                  {/* {show && (
                    <>
                      <div className="LocInp">
                        {errormsg ? <h3 className="Alrt">{errormsg}</h3> : null}
                        <CgClose className="close" onClick={hideLocInp} />
                        <form onSubmit={SaveOrderInfo}>
                          <div className="cont">
                            <CgPhone className="phone" />
                            <h2>User's Info</h2>
                            <p className="fnp">Full Name</p>
                            <input
                              type="text"
                              className="fn"
                              onClick={() => setErrormsg(null)}
                              placeholder="Enter Full Name"
                              name="name"
                              onChange={onChange}
                            />
                            <p className="emp">E-mail</p>
                            <input
                              type="text"
                              className="em"
                              defaultValue={isAuthenticated ? user.email : ""}
                              autoComplete="on"
                              onClick={() => setErrormsg(null)}
                              placeholder="example@gmail.com"
                              name="email"
                              onChange={onChange}
                            />
                          </div>
                        </form>

                        <div className="btn">
                          <Button type="submit">Submit</Button>
                        </div>
                      </div>
                    </>
                  )} */}

                  <div>
      {/* <progress value={progress} max="100" />
      <br />
      <button onClick={handleButtonClick}>Increment Progress</button> */}
      <NavLink to={link}>
        <StyledButton2>{status}</StyledButton2>
      </NavLink>
    </div>
                  <NavLink to="/returnorder">
                    <StyledButton
                      disabled={
                        new Date() - new Date(date) >7 * 24 * 60 * 60 * 1000
                      }
                    >
                      Return Order
                    </StyledButton>
                  </NavLink>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Btn = styled.button`
  text-decoration: none;
  width: auto;
  background-color: #c0924c;
  color: rgb(255 255 255);
  padding: 0.4rem 0.8rem;
  font-weight: bold;
  font-family: italic;
  border: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;

const StyledButton2 = styled.button`
  background-color: ${({ disabled }) => (disabled ? "lightgray" : "antiquewhite")};
  color: ${({ disabled }) => (disabled ? "gray" : "#c0924c")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  max-width: auto;
  padding: 1.4rem 2.4rem;
  font-weight: bold;
  font-family: italic;
  border: none;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    background-color: ${({ disabled }) => (disabled ? "lightgray" : "antiquewhite")};
    color: ${({ disabled }) => (disabled ? "gray" : "#c0924c")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    text-decoration: none;
    max-width: auto;
    padding: 0.4rem 0.8rem;
    font-weight: bold;
    font-family: italic;
    border: none;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;

    &:hover {
      box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
      box-shadow: ${({ theme }) => theme.colors.shadowSupport};
      transform: scale(0.96);
    }
    a {
      text-decoration: none;
      color: rgb(255 255 255);
      font-size: 1.8rem;
    }
  }
`;
const StyledButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? "lightgray" : "#c0924c")};
  color: ${({ disabled }) => (disabled ? "gray" : "rgb(255 255 255)")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  max-width: auto;
  padding: 1.4rem 2.4rem;
  font-weight: bold;
  font-family: italic;
  border: none;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    background-color: ${({ disabled }) => (disabled ? "lightgray" : "#c0924c")};
    color: ${({ disabled }) => (disabled ? "gray" : "rgb(255 255 255)")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    text-decoration: none;
    max-width: auto;
    padding: 0.4rem 0.8rem;
    font-weight: bold;
    font-family: italic;
    border: none;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;

    &:hover {
      box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
      box-shadow: ${({ theme }) => theme.colors.shadowSupport};
      transform: scale(0.96);
    }
    a {
      text-decoration: none;
      color: rgb(255 255 255);
      font-size: 1.8rem;
    }
  }
`;

const Wrapper = styled.section`
  padding: 9rem 0;

  .container {
    max-width: 150rem;
  }

  .grid {
    gap: 3.2rem;

    .grid-filter-column {
      grid-template-columns: 0.4fr 1fr;
    }
    .mb {
      margin-bottom: 20rem;
      .LocInp {
        display: flex;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(15px);
        width: 30%;
        gap: 10.6rem;
        height: 20%;
        position: absolute;
        padding-left: 50px;
        padding-right: 150px;
        justify-content: space-between;
        padding-top: 30px;
        top: 30%;
        font-size: 15px;
        font-family: cursive;
        font-weight: bold;
        left: 35%;
        color: white;
        opacity: 0.95;
        border: solid rgba(255, 255, 255, 0.1) 3px;
        border-radius: 20px;
        z-index: 0;

        .cont {
          display: grid;
          h3 {
            position: absolute;
            font-family: serif;
            margin-left: 5%;
            margin-top: -5%;
            color: black;
          }
          .ep {
            position: fixed;
            margin-top: 4.5%;
            height: 10%;
            width: 60%;
            margin-left: 10%;
          }
        }
        .btn {
          position: absolute;
          margin-top: 16%;
          margin-left: 57%;
          height: 10px;
          width: 5px;
        }
      }
    }

    figure {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      transition: all 0.5s linear;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        transition: all 0.2s linear;
        cursor: pointer;
      }
      &:hover::after {
        width: 100%;
      }
      &:hover img {
        transform: scale(1.8);
      }
      img {
        max-width: 120%;
        margin-top: 1.5rem;
        height: 30rem;
        transition: all 0.2s linear;
        padding: 3rem;
      }
    }

    .card {
      margin-top: 1%;

      height: 40rem;
      border: 0.1rem solid rgb(170 170 170 / 40%);
      margin-left: 0;

      .card-data {
        position: relative;
        padding: 3rem 20rem;
      }

      h3 {
        margin: 2rem 0;
        font-weight: 300;
        font-size: 2.4rem;
        text-transform: capitalize;
      }

      .btn {
        margin: 2rem 0;
        background-color: rgb(0 0 0 / 0%);
        border: 0.1rem solid gold;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gold;

        &:hover {
          background-color: gold;
        }

        &:hover a {
          color: #fff;
        }
        a {
          color: rgb(98 84 243);
          font-size: 1.4rem;
        }
      }
      .btn2 {
        display: flex;
        gap: 2rem;
        justify-content: space-between;
      }

      .btn-main .btn:hover {
        color: #fff;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 19rem 0;

    .container {
      max-width: 150rem;
    }

    .grid {
      gap: .5rem;
      .mb {
        margin-bottom: 10px;
        .LocInp {
          display: flex;
          background: rgba(255, 255, 255, 0.05);
          box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(15px);
          width: 30%;
          gap: 10.6rem;
          height: 18%;
          position: absolute;
          padding-left: 50px;
          padding-right: 150px;
          justify-content: space-between;
          padding-top: 30px;
          top: 20%;
          font-size: 15px;
          font-family: cursive;
          font-weight: bold;
          left: 30%;
          color: white;
          opacity: 0.95;
          border: solid rgba(255, 255, 255, 0.1) 3px;
          border-radius: 20px;
          z-index: 0;

          .cont {
            display: grid;
            h3 {
              position: absolute;
              font-family: serif;
              margin-left: -10%;
              margin-top: -5%;
              font-size: 12px;
              color: black;
            }
            .ep {
              position: fixed;
              margin-top: 17.5%;
              height: 10%;
              font-size: 10px;
              width: 60%;
              margin-left: -10%;
            }
          }
          .btn {
            position: absolute;
            margin-top: 35%;
            margin-left: 37%;
            height: 10px;
            width: 5px;
          }
        }
      }

      .grid-filter-column {
        grid-template-columns: 0.4fr 1fr;
      }

      figure {
        width: 21.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        transition: all 0.5s linear;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0%;
          height: 60%;
          background-color: rgba(255, 255, 255, 0.1);
          transition: all 0.2s linear;
          cursor: pointer;
        }
        &:hover::after {
          width: 100%;
        }
        &:hover img {
          transform: scale(1.8);
        }
        img {
          max-width: 120%;
          margin-top: 0rem;
          height: 30rem;
          transition: all 0.2s linear;
          padding: 0rem;
        }
      }

      .card {
        margin-top: 15rem;
        height: 39.2rem;
        border: 0.1rem solid rgb(170 170 170 / 40%);
        margin-left: -2.5rem;
        margin-bottom: -18em;
        .card-data {
          position: relative;
          padding: 2rem 0rem;
        }

        h3 {
          margin: 2rem 0;
          font-weight: 300;
          font-size: 2.4rem;
          text-transform: capitalize;
        }

        .btn {
          margin: 0.5rem 0;
          background-color: rgb(0 0 0 / 0%);
          border: 0.1rem solid gold;
          display: flex;
          width: 13rem;
          font-size: 10px;
          justify-content: center;
          align-items: center;
          color: gold;

          &:hover {
            background-color: gold;
          }

          &:hover a {
            color: #fff;
          }
          a {
            color: rgb(98 84 243);
            font-size: 1.4rem;
          }
        }

        .btn-main .btn:hover {
          color: #fff;
        }
      }
    }
  }
`;

export default MyOrder;
