import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";



const Cancel = () => {

    const [credentials, setCredentials] = useState({phone: "", email: "",productId: ""})
  const [errormsg, setErrormsg] = useState(null);
  const SaveOrderInfo =async (e) => {
    e.preventDefault();
    const {phone, email} = credentials;
    if(credentials.phone === '' ||
       credentials.email === '' 
      //  credentials.productId === ''
       ){
        setErrormsg('All fields are Required');
        return;
       }else{
        if(credentials.phone.length <10 || credentials.phone.length >11 ){
          setErrormsg('Please enter valid Mobile Number');
      return;
        }else{fetch("http://localhost:5000/api/cancelauth/cancelorder/", {
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
            },
            body: JSON.stringify({phone, email})
      })
                .then(res => res.json()).then(
                data => {
                  console.log(data);
                  if(data.error){
                  setErrormsg(data.error);
                  }
                  else{
                    alert('Your Details Saved Succesfully');
                    // fetch(handlePayment);
                  }
                }
              )}}
      }
      const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
      }

    const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit logic here
    console.log('Form submitted');
  };

  const {isAuthenticated, user} = useAuth0();


  
  if(!isAuthenticated){
    return(
      <>
      <EmptyDiv>
        <h2>Please Login First</h2>
        </EmptyDiv>
      </>
    )
  }
  
  return (
    <Wrapper>
      <BgImg>
        {/* <img src={img} alt=""/> */}

      </BgImg>

      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.956236729322!2d80.34708937527859!3d26.45713957692162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c4744af0fd0e5%3A0x6a03754e54991593!2sGhanta%20Ghar%20Chauraha%2C%20Dhana%20Khori%2C%20Mirpur%2C%20Kanpur%2C%20Uttar%20Pradesh%20208001!5e0!3m2!1sen!2sin!4v1698865444381!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{border:0}}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}

      <div className="container">
        <div className="grid grid-two-column">
        
        <div className="form">
          
        <form onSubmit={SaveOrderInfo}>
        <FormContainer>
        <h3>Enter Your Details</h3>
        {
          errormsg ? <p className="Alrt">{errormsg}</p> : null
        }
        <FormGroup>
          <FormLabel htmlFor="phone">Phone Number</FormLabel>
          <FormInput type="text" id="phone" name="phone" onClick={() => setErrormsg(null)}
              placeholder='+91**********' onChange={onChange} />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput type="text" id="email" name="email" onClick={() => setErrormsg(null)}
              placeholder='example@gmail.com' onChange={onChange} />
        </FormGroup>
        {/* <p className="mnp">Mobile Number</p>
                <input type="text" className="mn"  onClick={() => setErrormsg(null)}
              placeholder='+91**********'
              // onChange={(text) => setCredentials({ ...credentials, phone: text})}
              name="phone" onChange={onChange}

              />
              <p className="emp">E-mail</p>
                <input type="text" className="em" defaultValue={isAuthenticated ? user.email : ""} autoComplete="on" onClick={() => setErrormsg(null)}
              placeholder='example@gmail.com'
              // onChange={(text) => setCredentials({ ...credentials, email: text})}
              name="email" onChange={onChange}

              /> */}
      </FormContainer>
      <CheckboxLabel>
          <CheckboxInput
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        <p>Please read our Terms and Conditions carefully before proceeding .</p>
        </CheckboxLabel>
        <SubmitButton type="submit" enabled={isChecked}>
          Submit
        </SubmitButton>
    </form>
    </div>
        <div className="Description">
            {/* <h2>Cancel Our Master Jewelers for Exquisite Craftsmanship</h2>
            <p>Welcome to RK Jewells, where the art of jewelry manufacturing meets perfection.
               If you're seeking unparalleled craftsmanship and timeless designs for your Gold jewelry collections like :- </p> 
               <ul type= "disc">
                <li>Open Polki</li>
                <li>Kundan Polki</li>
               <li>Necklace Set</li>
               <li>Pendant Set</li>
               <li> Choker Set</li>
               <li>Full Jadau Set</li>
               <li>Bridal Set, Kada, Nath, Ring</li>
                <li>Or Any Design You Want</li>
               </ul>
               <p>
                Our team of master jewelers is ready to bring your visions to life.<br/> Cause We're here to cater to your desired designs which one do you want to wear once.</p>
                <h1>Send Your Details from Here <span>Below</span> <br/> And We'll Cancel You Very Soon.</h1>
                <FaLongArrowAltRight className="ico"/> */}

                {/* <TermsContainer> */}
                <TermsTitle>Terms and Conditions for Canceling an Order</TermsTitle>
      <TermsList>
        <TermsItem>All cancelations must be requested within 24 hours of placing the order.</TermsItem>
        <TermsItem>Cancelations requested after 24 hours may not be accepted.</TermsItem>
        <TermsItem>Refunds for canceled orders will be issued in the original form of payment and after we checks the product properly .</TermsItem>
        <TermsItem>Cancelation of custom or personalized orders may not be possible.</TermsItem>
        <TermsItem>Once an order has been shipped, it cannot be canceled.</TermsItem>
        <TermsItem>Customers are responsible for any return shipping costs for canceled orders.</TermsItem>
        <TermsItem>Refunds for canceled orders may take up to 7-10 business days to process.</TermsItem>
        <TermsItem>For any further questions or concerns, please go to the contact page .</TermsItem>
      </TermsList>

    {/* </TermsContainer> */}
          </div>
        </div>
      </div>

    </Wrapper>
  );
};



const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 20px;
  p{
    display:flex;
    max-width:70%;
    margin-top:-40px;
    margin-left:90px;
  }
`;

const CheckboxInput = styled.input`

  margin-left: -40rem;
  margin-top:3.4rem;
  size:30px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ enabled }) => (enabled ? '#c28428' : '#ccc')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
`;

const FormContainer = styled.section`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  h3{
    color: #c28428;
    margin-bottom:3rem;
    font-size:30px;
    font-weight:bold;
  }
  .Alrt{
      color:white;
      background-color:red;
      margin-top:-2%;
      margin-left:0%;
      padding-top:3px;
      padding-bottom:3px;
      padding-left:6px;
      padding-right:6px;
      border-radius:50px;
    }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const FormLabel = styled.label`
  display: block;
  font-weight: bold;
  font-size:15px;
  margin-bottom: 5px;
  letter-spacing:5px;
`;

const FormInput = styled.input`
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 18px;
  padding-left: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const FormButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;


const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:53.7%;
margin-top:14rem;
width:50%;
position:fixed;

`

const TermsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const TermsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const TermsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TermsItem = styled.li`
  margin-bottom: 10px;
  width:100%;
`;
  const Wrapper = styled.section`
    padding: 15rem 0 5rem 0;
    text-align: center;
    .common-heading{
      color: #c28428;
      padding-top:-5rem;
    }
    .grid{
      .form{
        margin-top:-4rem;
        margin-left:10rem;
        margin-right:-10rem;

        .chckbx{}
      }
      .Description{
        margin-right:-25rem;
        margin-left:25rem;
        .ico{
          margin-top:-9.5rem;
          font-size:10rem;
          margin-left:78rem;
        }
        h1{
          font-size:20px;
          color: #c28428;

          span{
            display:none;
          }
        }
        h2{
          margin-top:-20rem;
          color:#c0924c;
          font-size:30px;

        }
        p{
          font-size:20px;
        }
        ul{
          li{
            font-size:20px;
          }
        }
      }
    }
    h2{
      padding-top:15rem;
    }
    .container {
      margin-top: 5rem;
      margin-left:0rem;


      .cancel-form {
        max-width: 70rem;
        margin-top: auto;
        margin-right: auto;
        margin-bottom: auto;
        margin-left: 20rem;

        .cancel-inputs {
          display: flex;
          ${'' /* z-index:0; */}
          margin-right:-30rem;          
        margin-left:-5rem;
        margin-top:5rem;
          flex-direction: column;
          gap: 1rem;

          h2{
            margin-top:-25rem;
            color:#c0924c;
            font-size:30px;

          }
          .det{
            z-index:1;
            display:grid;
            margin-top:8.7rem;
            gap:2.3rem;
          }
          .txt{
            z-index:0;

          }
          .em{
            z-index:0;
          }
          .msg{
            z-index:0;
            padding-left:15rem;
            font-size:15px;
            gap:2rem;

          }

          
            p{
              margin-top:-27.3rem;
              font-weight:bold;
              padding-bottom:12rem;
              gap:2rem;
              margin-left:2rem;
              display:flex;
              justify-content:start;
              z-index:4;
              margin-bottom:12rem;
              width:12rem;
            }
          
          .des{
            background-color:white;
            z-index:0;
            padding:10px;
            border:1px solid rgb(78, 144, 205);

            p{
              color:black;
              ${'' /* font-family:cursive; */}

            }
            .fi{
              background-color: ${({ theme }) => theme.colors.white};
              width:100%;
              padding-left: 13rem;
              font-size:15px;
              border:none;
              background:transparent;

            }
          }

          input[type="submit"] {
            margin-top:-11rem;
            cursor: pointer;
            z-index:0;
            transition: all 0.2s;
            background-color:  #c0924c;
            color: rgb(255 255 255);
            padding: 1.4rem 2.4rem;
            font-weight:bold;
            font-family:italic;

            &:hover {
              background-color: rgb(255 255 255);
              border: 1px solid #c0924c;
              color: #c0924c;
              transform: scale(0.9);
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.media.mobile}) {
      .grid{
        .Description{
          margin-left:0rem;
          .ico{
            margin-top:-6rem;
            font-size:3rem;
            margin-left:20.2rem;
            margin-bottom:10rem;
            rotate:90deg;
          }
          h1{
            font-size:15px;
            span{
              display:grid;
            }
          }
          h2{
            padding-left:-20rem;
            font-size:15px;
            
          }
          p{
            marginleft:-10rem;
            font-size:10px;
          }
          ul{
            li{
              font-size:10px;
            }
          }
        }
        .cancel-form{
          margin-left:3rem;
          max-width:40rem;

          .cancel-inputs {
            display: flex;
            z-index:0;
            margin-right:-30rem;          
          margin-left:-1rem;
          width:40rem;
          margin-top:5rem;
            flex-direction: column;
            gap: 1rem;
  
            h2{
              margin-top:-25rem;
              color:#c0924c;
              font-size:30px;
  
            }
            .det{
              z-index:1;
              display:grid;
              margin-top:5.2rem;
              gap:2.9rem;
            }
          }  
        }
      }
    }
  `;

  const EmptyDiv = styled.div`
  display: grid;
  place-items: center;
  height: 98vh;
  `;

export default Cancel;
