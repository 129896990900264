import styled from "styled-components";
import SliderHome from "./Slider";
import LakshmiProducts from "./components/LakshmiProducts";
import img from "./components/rk__monogram.png"
import ImgCat from "./components/ImgCat";

const Lakshmi = () => {
  return (
    <>
  
      <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <ImgCat/>
      <LakshmiProducts />
     
  
    </>
  );
};

const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:15rem;
width:50%;
position:fixed;

`

export default Lakshmi;
