import styled from "styled-components";
import SliderHome from "./Slider";
import MensChainProducts from "./components/MensChainProducts";
import img from "./components/rk__monogram.png"
import ImgCat from "./components/ImgCat";
import MensCat from "./components/MensCat";

const MensChain = () => {
  return (
    <>
  
      <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <MensCat/>

      <MensChainProducts />
      
     
  
    </>
  );
};
const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:15rem;
width:50%;
position:fixed;

`
const GAp = styled.div`
padding-top:0.1rem;
`

export default MensChain;
