

import { createContext, useContext, useReducer, useEffect } from "react";
import reducer from "../reducer/wishReducer";

const WishContext = createContext();

const getLocalWishData = () => {
  let localWishData = localStorage.getItem("RK_Wish");
  // if (localWishData == []) {
  //   return [];
  // } else {
  //   return JSON.parse(localWishData);
  // }

  const parsedData  = JSON.parse(localWishData);
  if(!Array.isArray(parsedData)) return [];

  return parsedData;
};

const initialState = {
  // wish: [],
  wish: getLocalWishData(),
  total_item: "",
  total_price: "",
  shipping_fee: 50000,
};

const WishProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToWish = (id, color, amount, product) => {
    dispatch({ type: "ADD_TO_WISH", payload: { id, color, amount, product } });
  };

  // increment and decrement the product

  const setDecrease = (id) => {
    dispatch({ type: "SET_DECREMENT", payload: id });
  };

  const setIncrement = (id) => {
    dispatch({ type: "SET_INCREMENT", payload: id });
  };

  // to remove the individual item from wish
  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  // to clear the wish
  const clearWish = () => {
    dispatch({ type: "CLEAR_WISH" });
  };

  // to add the data in localStorage
  // get vs set

  useEffect(() => {
    // dispatch({ type: "WISH_TOTAL_ITEM" });
    // dispatch({ type: "WISH_TOTAL_PRICE" });
    dispatch({ type: "WISH_ITEM_PRICE_TOTAL" });

    localStorage.setItem("RK_Wish", JSON.stringify(state.wish));
  }, [state.wish]);

  return (
    <WishContext.Provider
      value={{
        ...state,
        addToWish,
        removeItem,
        clearWish,
        setDecrease,
        setIncrement,
      }}>
      {children}
    </WishContext.Provider>
  );
};

const useWishContext = () => {
  return useContext(WishContext);
};

export { WishProvider, useWishContext };


// import { createContext, useContext, useReducer, useEffect } from "react";
// import reducer from "../reducer/wishReducer";

// const WishContext = createContext();

// const getLocalWishData = () => {
//   let localWishData = localStorage.getItem("RK_Wish");
//   if (localWishData == []) {
//     return [];
//   } else {
//     return JSON.parse(localWishData);
//   }
// };

// const initialState = {
//   wish: getLocalWishData(),
//   total_item: "",
//   total_price: "",
//   shipping_fee: 50000,
// };

// const WishProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(reducer, initialState);

//   const addToWish = (id, color, amount, product) => {
//     dispatch({ type: "ADD_TO_WISH", payload: { id, color, amount, product } });
//   };
//   // increment and decrement the product

//   const setDecrease = (id) => {
//     dispatch({ type: "SET_DECREMENT", payload: id });
//   };

//   const setIncrement = (id) => {
//     dispatch({ type: "SET_INCREMENT", payload: id });
//   };

//   // to remove the individual item from wish
//   const removeItem = (id) => {
//     dispatch({ type: "REMOVE_ITEM", payload: id });
//   };

//   // to clear the wish
//   const clearWish = () => {
//     dispatch({ type: "CLEAR_WISH" });
//   };

//   // to add the data in localStorage
//   // get vs set

//   useEffect(() => {
//     dispatch({ type: "WISH_ITEM_PRICE_TOTAL" });

//     localStorage.setItem("RK_Wish", JSON.stringify(state.wish));
//   }, [state.wish]);

//   return (
//     <WishContext.Provider
//       value={{
//         ...state,
//         addToWish,
//         removeItem,
//         clearWish,
//         setDecrease,
//         setIncrement,
//       }}>
//       {children}
//     </WishContext.Provider>
//   );
// };

// const useWishContext = () => {
//   return useContext(WishContext);
// };

// export { WishProvider, useWishContext };
