import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Nav from "./Nav"
import img from "./rk_nav_mono.png";
import CatNav from './CatNav';

const ProductNav = () => {
  return (
    <MainHeader>
      <div className='mainheader'>

       
        <SetNav>

        <CatNav/>
        </SetNav>
      </div>
        
        
        
    </MainHeader>
  )
}


const MainHeader = styled.header`
   padding: 2rem 4.8rem;
   height: 6rem;
   overflow:hidden;
   background-color:antiquewhite;
   width:100%;
   display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
   align-items: flex-start;
   position: fixed;
   z-index:2;
   margin-top: 6.8rem; 
   
   h2{
    font-size:20px;
    font-weight:bold;
    margin-left:-35rem;
    text-shadow:1px 1px 5px gold;
    color:black;
   }

   .logo{
    height: 5rem;
   }

   @media (max-width: ${({ theme }) => theme.media.mobile}) {

    margin-top:5.5rem;
   
   `
 

const SetNav = styled.div`
    
`


export default ProductNav
