import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { CgClose, CgPhone} from "react-icons/cg";
import { useAuth0 } from '@auth0/auth0-react';
import { useCartContext } from '../context/cart_context';
import { Button } from './Button';



const DisableBtn = () => {

    const [credentials, setCredentials] = useState({name: "", email: "",productId: ""})
    const [errormsg, setErrormsg] = useState(null);
    const SaveOrderInfo =async (e) => {
      e.preventDefault();
      const {name, email,productId} = credentials;
      if(credentials.name === '' ||
         credentials.email === '' ||
         credentials.productId === ''
         ){
          setErrormsg('All fields are Required');
          return;
         }else{fetch("http://localhost:5000/api/cancelauth/orders/", {
          method: "POST", 
          headers: {
            "Content-Type": "application/json",
              },
              body: JSON.stringify({name, email,productId})
        })
                  .then(res => res.json()).then(
                  data => {
                    console.log(data);
                    if(data.error){
                    setErrormsg(data.error);
                    }
                    else{
                      alert('Your Details Saved Succesfully');
                      // fetch(handlePayment);
                    }
                  }
                )}
        }
        const onChange = (e) => {
          setCredentials({...credentials, [e.target.name]: e.target.value})
        }
  
        const { cart, clearCart,total_item, total_price, shipping_fee } = useCartContext();
  
  
    const [show, setShow] = useState();
  
    const showLocInp = () => {
      setShow(true);
    }
    const hideLocInp = () => {
      alert("Come Again Later");
      setShow(false);
    }
  
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
    const {isAuthenticated, user} = useAuth0();
  
  
    const handleClick = () => {
      localStorage.setItem('buttonClickTimestamp', Date.now());
      setIsButtonDisabled(true);
    };
  
    // Check if button should be disabled
    const disableButtonAfter3Days = () => {
      const buttonClickTimestamp = localStorage.getItem('buttonClickTimestamp');
      if (buttonClickTimestamp) {
        const threeDaysInMs = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
        const currentTime = Date.now();
        if (currentTime - parseInt(buttonClickTimestamp) > threeDaysInMs) {
          setIsButtonDisabled(true);
        }
      }
    };
  
    // Check on component mount
    useEffect(() => {
      disableButtonAfter3Days();
    }, []);

    const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/auth/fetchselected-products');
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);
  
  
  return (
    <>
    {products.map((curElem) => {
          const { id, name, image, price, description,date } = curElem;
          return (
            <div className="card grid grid-filter-column">
              

              <div className="card-data">
                
                <div className="btn2">
                {/* <StyledButton disabled={new Date() - new Date(date) > 24 * 60 * 60 * 1000} onClick={check}>Cancel Order</StyledButton> */}
                
                <StyledButton disabled={new Date() - new Date(date) > 24 * 60 * 60 * 1000} onClick={showLocInp}>Cancel Order</StyledButton>
          {
            show && (
              <>
              <div className="LocInp">
              {
          errormsg ? <h3 className="Alrt">{errormsg}</h3> : null
        }
              <CgClose className="close" onClick={hideLocInp}/>
              <form onSubmit={SaveOrderInfo}>
              {/* <div className="addr"> */}
              <div className="cont">
                  <CgPhone className="phone" />
                  <h2>User's Info</h2>
                  <p className="fnp">Full Name</p>
                <input type="text" className="fn"  onClick={() => setErrormsg(null)}
              placeholder='Enter Full Name'
              // onChange={(text) => setCredentials({ ...credentials, name: text})}
              name="name" onChange={onChange}

              />
              <p className="emp">E-mail</p>
                <input type="text" className="em" defaultValue={isAuthenticated ? user.email : ""} autoComplete="on" onClick={() => setErrormsg(null)}
              placeholder='example@gmail.com'
              // onChange={(text) => setCredentials({ ...credentials, email: text})}
              name="email" onChange={onChange}

              />
                
              
                {/* <div id="cart-item-id">
          {products.map((curElem) => {
            return <CartItem2 key={curElem.id} {...curElem} />;
          })}<p>{(shipping_fee + (shipping_fee * (total_item-1))*70/100) + ((total_price * 3)/100) + total_price}</p> */}
          {/* <CgCopy className="copy" onClick={handleCopy}/> */}
        {/* </div> */}
        {/* <div className="copyp">
        <p>* Copy & Paste this Code </p>
        </div>
                <input type="text" id="cp"  onClick={() => setErrormsg(null)}
              placeholder='Paste Here'
              // onChange={(text) => setCredentials({ ...credentials, state: text})}
              name="productId" onChange={onChange}

              /> */}

                </div>
                </form>
                
              <div className="btn">
              {/* <Button onClick={() => {
                SaveOrderInfo();
              }}>Submit</Button> */}
              <Button type="submit">Submit</Button>
              </div>
                
                </div>
                
              </>
            )
          }

          <StyledButton disabled={new Date() - new Date(date) > 24 * 60 * 60 * 1000}>Return Order</StyledButton>

                </div>
              </div>
            </div>
          );
        })}
</>
  )
}

const StyledButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? 'lightgray' : '#c0924c')};
  color: ${({ disabled }) => (disabled ? 'gray' : 'rgb(255 255 255)')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;
  max-width: auto;
  ${'' /* background-color:  #c0924c; */}
  ${'' /* color: rgb(255 255 255); */}
  padding: 1.4rem 2.4rem;
  font-weight:bold;
  font-family:italic;
  border: none;
  text-transform: uppercase;
  text-align: center;
  ${'' /* cursor: pointer; */}
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  /* Add more styles as needed */

  &:hover{
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;

export default DisableBtn
