import styled from "styled-components";
import SliderHome from "./Slider";
import GoldProducts from "./components/GoldProducts";
import img from "./components/rk__monogram.png"

const Gold = () => {

  return (
    <>
  
      <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <GoldProducts />
     
  
    </>
  );
};


const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:15rem;
width:50%;
position:fixed;

`
export default Gold;
