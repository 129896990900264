import styled from "styled-components";
import SliderHome from "./Slider";
import WomensBraceletProducts from "./components/WomensBraceletProducts";
import img from "./components/rk__monogram.png"
import ImgCat from "./components/ImgCat";
import MensCat from "./components/MensCat";
import WomenCat from "./components/WomenCat";

const WomensBracelet = () => {
  return (
    <>
  
      <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <WomenCat/>

      <WomensBraceletProducts />
      
     
  
    </>
  );
};
const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:15rem;
width:50%;
position:fixed;

`
const GAp = styled.div`
padding-top:0.1rem;
`

export default WomensBracelet;
