import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from './styles/Button';

const PromoCode = ({ applyPromoCode }) => {
  const [promoCode, setPromoCode] = useState('');

  const handleChange = (e) => {
    setPromoCode(e.target.value);
  };

  const handleApply = () => {
    applyPromoCode(promoCode);
    setPromoCode('');
  };

  return (
    <Wrapper>
    <p>Promo Code:</p>
      <input
        type="text"
        placeholder="Enter A Promo Code"
        value={promoCode}
        onChange={handleChange}
      />
      <div className='btn'>
      <button onClick={handleApply}>Apply</button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
margin-bottom:5rem;
display:grid;

p{
    margin-left:-6.4rem;
    font-weight:bold;
}

input{
    margin-left:-6.4rem;

}
.btn{
    margin-top:1rem;
    margin-left:10rem;

    button{
        text-decoration: none;
  max-width: auto;
  background-color:  #c0924c;
  color: rgb(255 255 255);
  padding: .5rem 1.2rem;
  font-weight:bold;
  font-family:italic;
  border: none;
  text-transform: uppercase;
  border-radius:20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
    }
}

@media (max-width: ${({ theme }) => theme.media.mobile}) {
  margin-bottom:5rem;
display:grid;

p{
    margin-left:5rem;
    font-weight:bold;
}

input{
    margin-left:4rem;
    width:50%;

}
.btn{
    margin-top:-5rem;
    margin-left:30rem;

    button{
        text-decoration: none;
  max-width: auto;
  background-color:  #c0924c;
  color: rgb(255 255 255);
  padding: .5rem 1.2rem;
  font-weight:bold;
  font-family:italic;
  border: none;
  text-transform: uppercase;
  border-radius:20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
    }
}
}

`

export default PromoCode;
