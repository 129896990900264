import { createContext, useContext, useEffect, useReducer } from "react";
import axios from "axios";
import reducer from "../reducer/myOrderReducer";

const MyOrderContext = createContext();

const API = "http://localhost:5000/api/auth/fetchselected-products";

const initialState = {
  isLoading: false,
  isError: false,
  myOrders: [],
  allMyOrders: [],
};

const MyOrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getMyOrders = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const myOrders = await res.data;
      
      dispatch({ type: "SET_ALL_DATA", payload: myOrders });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const getSingleMyOrder = async (url) => {
    dispatch({ type: "SET_SINGLE_LOADING" });
    try {
      const res = await axios.get(url);
      const singleMyOrder = await res.data;
      dispatch({ type: "SET_SINGLE_PRODUCT", payload: singleMyOrder });
    } catch (error) {
      dispatch({ type: "SET_SINGLE_ERROR" });
    }
  };

  useEffect(() => {
    getMyOrders(API);
  }, []);

  return (
    <MyOrderContext.Provider value={{ ...state, getSingleMyOrder }}>
      {children}
    </MyOrderContext.Provider>
  );
};

// custom hooks
const useMyOrderContext = () => {
  return useContext(MyOrderContext);
};

export { MyOrderProvider, useMyOrderContext };
