import { createContext, useContext, useEffect, useReducer } from "react";
import axios from "axios";
import reducer from "../reducer/productReducer";

const AppContext = createContext();

const API = "https://midnight-sudden-headlight.glitch.me/api/products";

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProducts: [],
  BichiyaProducts: [],
  PayalProducts: [],
  BraceletProducts: [],
  ItalianProducts: [],
  ItalianPayalProducts: [],
  ItalianChainProducts: [],
  GaneshTempleProducts: [],
  HanumanTempleProducts: [],
  LakshmiTempleProducts: [],
  Under1000Price: [],
  Under5000Price: [],
  Under10000Price: [],
  SilverProducts: [],
  GoldProducts: [],
  MensProducts: [],
  MensChainProducts: [],
  MensBraceletProducts: [],
  WomensChainProducts: [],
  WomensBraceletProducts: [],
  WomensProducts: [],
  allProducts: [],
  isSingleLoading: false,
  singleProduct: {},
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getProducts = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_API_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_BICHIYA_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_ALL_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_PAYAL_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_BRACELET_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_ITALIANPAYAL_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_ITALIANCHAIN_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_GANESHTEMPLE_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_HANUMANTEMPLE_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_LAKSHMITEMPLE_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_MENS_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_MENSCHAIN_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
      try {
        const res = await axios.get(url);
        const products = await res.data;
        
        dispatch({ type: "SET_MENSBRACELET_DATA", payload: products });
      } catch (error) {
        dispatch({ type: "API_ERROR" });
      }
    
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_WOMENSCHAIN_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
      try {
        const res = await axios.get(url);
        const products = await res.data;
        
        dispatch({ type: "SET_WOMENSBRACELET_DATA", payload: products });
      } catch (error) {
        dispatch({ type: "API_ERROR" });
      }
    
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_WOMENS_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_SILVER_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_GOLD_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_ITALIAN_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_UNDER1000_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_UNDER5000_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    try {
      const res = await axios.get(url);
      const products = await res.data;
      
      dispatch({ type: "SET_UNDER10000_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  // my 2nd api call for single product

  const getSingleProduct = async (url) => {
    dispatch({ type: "SET_SINGLE_LOADING" });
    try {
      const res = await axios.get(url);
      const singleProduct = await res.data;
      dispatch({ type: "SET_SINGLE_PRODUCT", payload: singleProduct });
    } catch (error) {
      dispatch({ type: "SET_SINGLE_ERROR" });
    }
  };

  useEffect(() => {
    getProducts(API);
  }, []);

  return (
    <AppContext.Provider value={{ ...state, getSingleProduct }}>
      {children}
    </AppContext.Provider>
  );
};

// custom hooks
const useProductContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useProductContext };
