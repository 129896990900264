import styled from "styled-components";
import { useCartContext } from "./context/cart_context";
import CartItem from "./components/CartItem";
import { NavLink } from "react-router-dom";
import { Button } from "./styles/Button";
import FormatPrice from "./Helpers/FormatPrice";
import img from "./components/rk__monogram.png";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useState } from "react";
import { CgBox, CgClose, CgPhone, CgPin } from "react-icons/cg";
import CartItem2 from "./components/CartItem2";
import PromoCode from "./PromoCode";
import AddToMyOrder from "./components/AddToMyOrder";

const Cart = () => {
  const [showClass, setShowClass] = useState(true);

  const [discountedTotal, setDiscountedTotal] = useState(0);
  const applyPromoCode = (promoCode) => {
    if (promoCode === "RKJ10") {
      const discount = total_price * 0.1;
      setDiscountedTotal(discount);
      // handlePayment();
    }
  };

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    landmark: "",
    pincode: "",
    phone: "",
    altPhone: "",
    productId: "",
  });
  const [errormsg, setErrormsg] = useState(null);
  const SaveOrderInfo = async (e) => {
    e.preventDefault();
    const {
      name,
      email,
      address,
      city,
      state,
      country,
      landmark,
      pincode,
      phone,
      altPhone,
      productId,
    } = credentials;
    if (
      credentials.name === "" ||
      credentials.email === "" ||
      credentials.city === "" ||
      credentials.state === "" ||
      credentials.country === "" ||
      credentials.address === "" ||
      credentials.pincode === "" ||
      credentials.phone === "" ||
      credentials.altPhone === "" ||
      credentials.landmark === "" ||
      credentials.productId === ""
    ) {
      setErrormsg("All fields are Required");
      return;
    } else {
      if (credentials.phone === credentials.altPhone) {
        setErrormsg("Phone numbers must be different");
        return;
      } else {
        if (credentials.phone.length < 10 || credentials.phone.length > 11) {
          setErrormsg("Please enter valid Mobile Number");
          return;
        } else {
          if (
            credentials.altPhone.length < 10 ||
            credentials.altPhone.length > 11
          ) {
            setErrormsg("Please enter valid Alternate Mobile Number");
            return;
          } else {
            fetch("http://localhost:5000/api/auth/orders/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name,
                email,
                address,
                city,
                state,
                country,
                landmark,
                pincode,
                phone,
                altPhone,
                productId,
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                if (data.error) {
                  setErrormsg(data.error);
                } else {
                  alert("Your Details Saved Succesfully");
                  handlePayment();
                }
              });
          }
        }
      }
    }
  };
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleCopy = () => {
    var text = document.getElementById("cp");
    text.select();
    navigator.clipboard.writeText(text.value);
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductSelect = () => {
    let sel = localStorage.getItem("RK_Cart");
    const newItem2 = JSON.parse(sel);
    setSelectedProduct(newItem2);
  };

  const sendSelectedProduct = async () => {
    try {
      let sel = localStorage.getItem("RK_Cart");
      const newItem2 = JSON.parse(sel);
      fetch("http://localhost:5000/api/auth/selected-product/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newItem2),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        });
    } catch (error) {
      console.error("Error sending product details to backend:", error);
    }
  };

  const { cart, clearCart, total_item, total_price, shipping_fee } =
    useCartContext();
  const { isAuthenticated, user } = useAuth0();

  const [show, setShow] = useState();

  const showLocInp = () => {
    setShow(true);
  };
  const hideLocInp = () => {
    alert("Come Again Later");
    setShow(false);
  };

  const initPayment = (data) => {
    const options = {
      key: "rzp_test_7pEW0zy472JVwk",
      amount: data.amount,
      currency: data.currency,
      name:"RK Jewells",
      description: "Checking is on going",
      order_id: data.id,
      handle: async (response) => {
        try {
          const verifyUrl = "http://localhost:8080/api/payment/verify";
          const { data } = await axios.post(verifyUrl, response);
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#c0924c",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const { stock } = cart;

  const handlePayment = async () => {
    try {
      const orderUrl = "http://localhost:8080/api/payment/orders";
      const { data } = await axios.post(orderUrl, {
        amount:
          shipping_fee +
          (shipping_fee * (total_item - 1) * 70) / 100 +
          ((total_price - discountedTotal) * 3) / 100 +
          total_price -
          discountedTotal,
      });
      console.log(data);
      initPayment(data.data);
      sendSelectedProduct();
      clearCart();
    } catch (error) {
      console.log(error);
    }
  };

  if (cart.length === 0) {
    return (
      <>
        <BgImg>
          <img src={img} alt="" />
        </BgImg>
        <EmptyDiv>
          <NavLink to="/myorders">
            <div className="MyOrders">
              <CgBox />
              <p>My Orders</p>
            </div>
          </NavLink>
          <h3>Your Cart is Empty </h3>
          <NavLink className="Buy" to="/products">
            <Button>
              <p>Let's fill it up with some amazing jewellery!</p>
            </Button>
          </NavLink>
        </EmptyDiv>
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <div className="container">
          {isAuthenticated && (
            <div className="cart-user--profile">
              <img src={user.picture} alt={user.name} />
              <h2 className="cart-user--name">{user.name}</h2>
            </div>
          )}
          <NavLink to="/myorders">
            <div className="MyOrders">
              <CgBox />
              <p>My Orders</p>
            </div>
          </NavLink>
          <div className="cart_heading grid grid-five-column">
            <p>Item</p>
            <p className="cart-hide">Price</p>
            <p>Quantity</p>
            <p className="cart-hide">Subtotal</p>
            <p>Remove</p>
          </div>
          <hr />
          <div className={showClass ? "cart-item" : "non"}>
            {cart.map((curElem) => {
              return <CartItem key={curElem.id} {...curElem} />;
            })}
          </div>

          <hr />
          <div className="cart-two-button">
            <NavLink to="/products">
              <Button> continue Shopping </Button>
            </NavLink>
            <Button className="btn btn-clear" onClick={clearCart}>
              clear cart
            </Button>
          </div>
          <div>{stock > 0 && <AddToMyOrder product={cart} />}</div>

          <div className="order-total--amount">
            <div className="order-total--subdata">
              <div>
                <p>Product Price:</p>
                <p>
                  <FormatPrice price={total_price} />
                </p>
              </div>
              <div>
                <p>Total Discounts:</p>
                <p>
                  <FormatPrice price={discountedTotal} />
                </p>
              </div>
              <div>
                <p>GST @3%:</p>
                <p>
                  <FormatPrice
                    price={((total_price - discountedTotal) * 3) / 100}
                  />
                </p>
              </div>

              <div>
                <p>shipping fee:</p>
                <p>
                  <FormatPrice
                    price={
                      shipping_fee +
                      (shipping_fee * (total_item - 1) * 70) / 100
                    }
                  />
                </p>
              </div>
              <hr />
              <div className="total">
                <p>order total:</p>
                <p>
                  <FormatPrice
                    price={
                      shipping_fee +
                      (shipping_fee * (total_item - 1) * 70) / 100 +
                      ((total_price - discountedTotal) * 3) / 100 +
                      total_price -
                      discountedTotal
                    }
                  />
                </p>
              </div>
            </div>
            <div>
              <PromoCode applyPromoCode={applyPromoCode} />
            </div>
            <div className="cntbtn">
              <Button onClick={showLocInp}>Continue</Button>
            </div>
            {show && (
              <>
                <div className="LocInp">
                  {errormsg ? <h3 className="Alrt">{errormsg}</h3> : null}
                  <CgClose className="close" onClick={hideLocInp} />
                  <form onSubmit={SaveOrderInfo}>
                    <div className="addr">
                      <CgPin className="pin" />
                      <h2>User's Address</h2>
                      <p className="cmadp">Complete Address</p>
                      <input
                        type="text"
                        className="cmad"
                        onClick={() => setErrormsg(null)}
                        placeholder="House no./ Road Name/ Area/ Colony"
                        name="address"
                        onChange={onChange}
                      />
                      <p className="lmp">Landmark</p>
                      <input
                        type="text"
                        className="lm"
                        onClick={() => setErrormsg(null)}
                        placeholder="Any Famous place nearby"
                        name="landmark"
                        onChange={onChange}
                      />
                      <p className="pcp">PinCode</p>
                      <input
                        type="text"
                        className="pc"
                        onClick={() => setErrormsg(null)}
                        placeholder="20**01"
                        name="pincode"
                        onChange={onChange}
                      />
                      <p className="ctp">City</p>
                      <input
                        type="text"
                        className="ct"
                        onClick={() => setErrormsg(null)}
                        placeholder="Enter City"
                        name="city"
                        onChange={onChange}
                      />
                      <p className="stp">State</p>
                      <input
                        type="text"
                        className="st"
                        onClick={() => setErrormsg(null)}
                        placeholder="Enter State"
                        name="state"
                        onChange={onChange}
                      />
                      <p className="cnp">Country</p>
                      <input
                        type="text"
                        className="cn"
                        onClick={() => setErrormsg(null)}
                        placeholder="Enter Country"
                        name="country"
                        onChange={onChange}
                      />

                      <div id="cart-item-id">
                        {cart.map((curElem) => {
                          return <CartItem2 key={curElem.id} {...curElem} />;
                        })}
                        <p>
                          {shipping_fee +
                      (shipping_fee * (total_item - 1) * 70) / 100 +
                      ((total_price - discountedTotal) * 3) / 100 +
                      total_price -
                      discountedTotal}
                        </p>
                      </div>
                      <div className="copyp">
                        <p>* Copy & Paste this Code </p>
                      </div>
                      <input
                        type="text"
                        id="cp"
                        onClick={() => setErrormsg(null)}
                        placeholder="Enter the Code"
                        name="productId"
                        onChange={onChange}
                      />
                    </div>
                  </form>
                  <div className="line" />
                  <form onSubmit={SaveOrderInfo}>
                    <div className="cont">
                      <CgPhone className="phone" />
                      <h2>User's Info</h2>
                      <p className="fnp">Full Name</p>
                      <input
                        type="text"
                        className="fn"
                        onClick={() => setErrormsg(null)}
                        placeholder="Enter Full Name"
                        name="name"
                        onChange={onChange}
                      />
                      <p className="mnp">Mobile Number</p>
                      <input
                        type="text"
                        className="mn"
                        onClick={() => setErrormsg(null)}
                        placeholder="+91**********"
                        name="phone"
                        onChange={onChange}
                      />
                      <p className="amp">Alternate Mobile</p>
                      <input
                        type="text"
                        className="am"
                        onClick={() => setErrormsg(null)}
                        placeholder="+91**********"
                        name="altPhone"
                        onChange={onChange}
                      />
                      <p className="emp">E-mail</p>
                      <input
                        type="text"
                        className="em"
                        defaultValue={isAuthenticated ? user.email : ""}
                        autoComplete="on"
                        onClick={() => setErrormsg(null)}
                        placeholder="example@gmail.com"
                        name="email"
                        onChange={onChange}
                      />
                      {/* <input
                        type="password"
                        id="psswrd"
                        onClick={() => setErrormsg(null)}
                        placeholder="Set Password"
                        name="password"
                        onChange={onChange}
                      /> */}
                      <div className="btn">
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const EmptyDiv = styled.div`
  display: grid;
  place-items: center;
  height: 98vh;

  .MyOrders {
    position: absolute;
    display: flex;
    margin-left: 30.35%;
    margin-top: 4.15%;
    font-size: 30px;
    color: #c0924c;
    background-color: black;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 0px;
    padding-right: 5px;
    border-radius: 10px;
    cursor: pointer;

    p {
      display: flex;
      font-size: 25px;
      place-items: center;
      margin-top: -3px;
      margin-left: 7px;
      color: #c0924c;
    }
  }

  .Buy {
    margin-top: -30rem;
    z-index: 1;
    p {
      color: antiquewhite;
      font-weight: bold;
      letter-spacing: 0.2rem;
      font-size: 10px;
    }
  }
  h3 {
    font-size: 4.2rem;
    text-transform: capitalize;
    font-weight: 300;
    margin-bottom: 0rem;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .MyOrders {
    position: absolute;
    display: flex;
    margin-left: -20%;
    margin-top: 27.15%;
    font-size: 30px;
    color: #c0924c;
    background-color: black;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 0px;
    padding-right: 5px;
    border-radius: 10px;
    cursor: pointer;

    p {
      display: flex;
      font-size: 25px;
      place-items: center;
      margin-top: -3px;
      margin-left: 7px;
      color: #c0924c;
    }
  }
    h3 {
      margin-top: -6rem;
    }
    .Buy {
      margin-top: -50rem;
      p {
        color: antiquewhite;
        font-weight: bold;
        letter-spacing: 0.1rem;
        font-size: 10px;
      }
    }
  }
`;

const Wrapper = styled.section`
  padding: 16rem 0;

  .grid-four-column {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-five-column {
    grid-template-columns: repeat(4, 1fr) 0.3fr;
    text-align: center;
    align-items: center;
  }
  .cart-heading {
    text-align: center;
    text-transform: uppercase;
  }
  hr {
    margin-top: 1rem;
    background-color: grey;
    height: 2px;
  }
  .cart-item {
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }
  .non {
    display: none;
  }

  .cart-user--profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 5.4rem;

    img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
    }
    h2 {
      font-size: 2.4rem;
    }
  }
  .cart-user--name {
    text-transform: capitalize;
  }
  .MyOrders {
    position: absolute;
    display: flex;
    margin-left: 70%;
    margin-top: -8%;
    font-size: 30px;
    color: #c0924c;
    background-color: black;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 0px;
    padding-right: 5px;
    border-radius: 10px;
    cursor: pointer;

    p {
      display: flex;
      font-size: 25px;
      place-items: center;
      margin-top: -3px;
      margin-left: 7px;
      color: #c0924c;
    }
  }
  .cart-image--name {
    /* background-color: red; */
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 0.4fr 1fr;
    text-transform: capitalize;
    text-align: left;
    img {
      max-width: 5rem;
      height: 5rem;
      object-fit: contain;
      color: transparent;
    }

    .color-div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      .color-style {
        width: 1.4rem;
        height: 1.4rem;

        border-radius: 50%;
      }
    }
  }

  .cart-two-button {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .btn-clear {
      background-color: #e74c3c;
    }
  }

  .amount-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    font-size: 1.4rem;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }

  .remove_icon {
    font-size: 1.6rem;
    color: #e74c3c;
    cursor: pointer;
  }

  .order-total--amount {
    width: 100%;
    margin: 4.8rem 0;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .order-total--subdata {
      border: 0.1rem solid #f0f0f0;
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      padding: 3.2rem;
    }
    div {
      display: flex;
      gap: 3.2rem;
      justify-content: space-between;
    }

    .total {
      background-color: #fafafa;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.heading};
    }

    ${
      "" /* .total{
      font-weight: bold;
      color: ${({ theme }) => theme.colors.heading};
    } */
    }

    .Alrt {
      position: fixed;
      color: white;
      background-color: red;
      max-width:25rem;
      margin-top: -2%;
      margin-left: 40%;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 50px;
      z-index:999;
    }
    .LocInp {
      display: flex;
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(15px);
      ${"" /* background-color:#c0924c; */}
      width:80%;
      gap: 10.6rem;
      height: 60%;
      position: fixed;
      padding-left: 50px;
      padding-right: 150px;
      justify-content: space-between;
      padding-top: 30px;
      top: 30%;
      font-size: 15px;
      ${'' /* font-family: cursive; */}
      font-weight: bold;
      left: 10%;
      color: white;
      opacity: 0.95;
      border: solid rgba(255, 255, 255, 0.1) 3px;
      border-radius: 20px;
      z-index: 9999;

      .close {
        display: flex;
        position: fixed;
        margin-left: 92%;
        margin-top: -1.6%;
        font-size: 3rem;
        background-color: red;
        color: white;
        cursor: pointer;
        border-radius: 0 10px 0 0;
      }
      .addr {
        display: grid;
        .pin {
          position: absolute;
          color: black;
          font-size: 4rem;
          margin-left:10%;
        }

        h2 {
          position: absolute;
          ${'' /* font-family: cursive; */}
          margin-left: 15%;
          margin-top: -1%;
          color: black;
        }

        .cmadp {
          position: fixed;
          margin-top: 6%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: 18%;
        }
        .cmad {
          position: fixed;
          margin-top: 8.5%;
          height: 10%;
          width: 40%;
          margin-left: 5%;
        }
        .lmp {
          position: fixed;
          margin-top: 13%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: 6%;
        }
        .lm {
          position: fixed;
          margin-top: 15.5%;
          height: 10%;
          width: 30%;
          margin-left: 5%;
        }
        .pcp {
          position: fixed;
          margin-top: 13%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: 41%;
        }
        .pc {
          position: fixed;
          margin-top: 15.5%;
          height: 10%;
          width: 15%;
          margin-left: 40%;
        }
        .ctp {
          position: fixed;
          margin-top: 20%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: 6%;
        }
        .ct {
          position: fixed;
          margin-top: 22.5%;
          height: 10%;
          width: 15%;
          margin-left: 5%;
        }
        .stp {
          position: fixed;
          margin-top: 20%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: 23.5%;
        }
        .st {
          position: fixed;
          margin-top: 22.5%;
          height: 10%;
          width: 15%;
          margin-left: 22.5%;
        }
        .cnp {
          position: fixed;
          margin-top: 20%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: 41%;
        }
        .cn {
          position: fixed;
          margin-top: 22.5%;
          height: 10%;
          width: 15%;
          margin-left: 40%;
        }
        #cart-item-id {
          position: fixed;
          background-color: grey;
          color: black;
          font-weight: bold;
          margin-top: 28%;
          height: 10%;
          width: 30%;
          justify-content: center;
          padding-left: 5px;
          align-items: center;
          margin-left: 5%;
          gap: 0;
          overflow: hidden;

          p {
            font-weight: bold;
            color: black;

            font-family: jokerman;
          }
        }
        .copyp {
          position: fixed;
          margin-top: 31.3%;
          margin-left: 7%;

          p {
            color: blue;
            font-weight: bold;
            ${'' /* font-family: cursive; */}
          }
        }

        #cp {
          position: fixed;
          margin-top: 28%;
          height: 10%;
          width: 15%;
          margin-left: 40%;
        }
      }
      .line {
        width: 1px;
        height: 95%;
        background-color: grey;
        margin-left: 40%;
      }
      .cont {
        display: grid;
        .phone {
          margin-left: -20%;
          position: absolute;
          color: black;
          font-size: 4rem;
        }
        h2 {
          position: absolute;
          ${'' /* font-family: cursive; */}
          margin-left: -15%;
          margin-top: -1%;
          color: black;
        }
        .fnp {
          position: fixed;
          margin-top: 6%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: -10%;
        }
        .fn {
          position: fixed;
          margin-top: 8.5%;
          height: 10%;
          width: 30%;
          margin-left: -21%;
        }
        .mnp {
          position: fixed;
          margin-top: 13%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: -20%;
        }
        .mn {
          position: fixed;
          margin-top: 15.5%;
          height: 10%;
          width: 14%;
          margin-left: -21%;
        }
        .amp {
          position: fixed;
          margin-top: 13%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: -5%;
        }
        .am {
          position: fixed;
          margin-top: 15.5%;
          height: 10%;
          width: 15%;
          margin-left: -6%;
        }
        .emp {
          position: fixed;
          margin-top: 20%;
          font-size: 20px;
          font-weight: bold;
          color: black;
          margin-left: -8%;
        }
        .em {
          position: fixed;
          margin-top: 22.5%;
          height: 10%;
          width: 30%;
          margin-left: -21%;
        }
        #psswrd {
          position: fixed;
          margin-top: 28%;
          height: 10%;
          width: 15%;
          margin-left: -21%;
        }
        .btn {
          position: absolute;
          margin-top: 28%;
          button{
            text-decoration: none;
  max-width: auto;
  background-color:  #c0924c;
  color: rgb(255 255 255);
  padding: 1.4rem 2.4rem;
  font-weight:bold;
  font-family:italic;
  border: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-five-column {
      grid-template-columns: 1.5fr 1fr 0.5fr;
    }
    .cart-hide {
      display: none;
    }

    .cart-two-button {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      gap: 2.2rem;
    }

    .MyOrders {
      position: absolute;
      display: flex;
      margin-left: 62%;
      margin-top: -22.5%;
      font-size: 20px;
      color: #c0924c;
      background-color: black;
      padding-top: 5px;
      padding-left: 5px;
      padding-bottom: 5px;
      padding-right: 5px;
      border-radius: 10px;
      cursor: pointer;

      p {
        display: flex;
        font-size: 15px;
        place-items: center;
        margin-top: -1px;
        margin-left: 7px;
        color: #c0924c;
      }
    }

    .order-total--amount {
      width: 100%;
      text-transform: capitalize;
      justify-content: flex-start;
      align-items: flex-start;

      .order-total--subdata {
        width: 100%;
        border: 0.1rem solid #f0f0f0;
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        padding: 3.2rem;
      }

      .cntbtn {
        margin-left: 60.2%;
      }

      .Alrt {
        position: fixed;
        color: white;
        background-color: red;
        margin-top: 2%;
        margin-left: 25%;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 50px;
      }
      .LocInp {
        display: flex;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(15px);
        ${"" /* background-color:#c0924c; */}
        width:90%;
        gap: 10.6rem;
        height: 75%;
        position: fixed;
        padding-left: 10px;
        padding-right: 50px;
        justify-content: space-between;
        padding-top: 30px;
        top: 20%;
        font-size: 15px;
        ${'' /* font-family: cursive; */}
        font-weight: bold;
        left: 5%;
        color: white;
        opacity: 0.95;
        border: solid rgba(255, 255, 255, 0.1) 3px;
        border-radius: 20px;
        z-index: 9999;

        .close {
          display: flex;
          position: fixed;
          margin-left: 88%;
          margin-top: -2.8rem;
          font-size: 3rem;
          background-color: red;
          color: white;
          cursor: pointer;
        }
        .addr {
          display: grid;
          .pin {
            position: absolute;
            color: black;
            font-size: 3rem;
            top: 1rem;
            left: 23%;
            margin-left:0%;

          }

          h2 {
            position: absolute;
            ${'' /* font-family: cursive; */}
            margin-left: 28%;
            margin-top: -3rem;
            color: black;
            font-size: 2.5rem;
          }

          .cmadp {
            position: fixed;
            margin-top: 11.5%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: 30%;
          }
          .cmad {
            position: fixed;
            margin-top: 17%;
            height: 5%;
            font-size: 10px;
            width: 85%;
            margin-left: 5%;
          }
          .lmp {
            position: fixed;
            margin-top: 28.5%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: 6%;
          }
          .lm {
            position: fixed;
            font-size: 10px;
            margin-top: 34%;
            height: 5%;
            width: 42%;
            margin-left: 5%;
          }
          .pcp {
            position: fixed;
            margin-top: 28.5%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: 51%;
          }
          .pc {
            position: fixed;
            margin-top: 34%;
            height: 5%;
            font-size: 10px;
            width: 40%;
            margin-left: 50%;
          }
          .ctp {
            position: fixed;
            margin-top: 45.5%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: 6%;
          }
          .ct {
            position: fixed;
            margin-top: 51%;
            height: 5%;
            font-size: 10px;
            width: 42%;
            margin-left: 5%;
          }
          .stp {
            position: fixed;
            margin-top: 45.5%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: 51%;
          }
          .st {
            position: fixed;
            margin-top: 51%;
            height: 5%;
            font-size: 10px;
            width: 40%;
            margin-left: 50%;
          }
          .cnp {
            position: fixed;
            margin-top: 62.5%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: 6%;
          }
          .cn {
            position: fixed;
            margin-top: 68%;
            font-size: 10px;
            height: 5%;
            width: 42%;
            margin-left: 5%;
          }
          #cart-item-id {
            position: fixed;
            background-color: grey;
            color: black;
            font-weight: bold;
            margin-top: 78.5%;
            height: 5%;
            width: 85%;
            justify-content: center;
            padding-left: 5px;
            align-items: center;
            margin-left: 5%;
            gap: 0;
            overflow: hidden;

            p {
              font-weight: bold;
              color: black;
              letter-spacing: 0.5rem;
              font-family: jokerman;
            }
          }
          .copyp {
            position: fixed;
            margin-top: 87.8%;
            margin-left: 22%;

            p {
              color: blue;
              font-weight: bold;
              ${'' /* font-family: cursive; */}
            }
          }

          #cp {
            position: fixed;
            margin-top: 68%;
            height: 5%;
            font-size: 10px;
            width: 40%;
            margin-left: 50%;
          }
        }
        .line {
          width: 10rem;
          height: 2px;
          background-color: grey;
          margin-left: 5%;
          margin-top: 37rem;
          display: none;
        }
        .cont {
          display: grid;
          .phone {
            position: absolute;
            color: black;
            font-size: 3rem;
            top: 47rem;
            left: 50%;
          }
          h2 {
            position: absolute;
            ${'' /* font-family: cursive; */}
            margin-left: -47%;
            margin-top: 43rem;
            color: black;
            font-size: 2.5rem;
          }
          .fnp {
            position: fixed;
            margin-top: 103%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: -44%;
          }
          .fn {
            position: fixed;
            margin-top: 108.5%;
            height: 5%;
            width: 85%;
            margin-left: -77%;
            font-size: 10px;
          }
          .mnp {
            position: fixed;
            margin-top: 120%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: -76%;
          }
          .mn {
            position: fixed;
            margin-top: 125.5%;
            height: 5%;
            width: 42%;
            margin-left: -77%;
            font-size: 10px;
          }
          .amp {
            position: fixed;
            margin-top: 120%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: -31%;
          }
          .am {
            position: fixed;
            margin-top: 125.5%;
            height: 5%;
            width: 40%;
            margin-left: -32%;
            font-size: 10px;
          }
          .emp {
            position: fixed;
            margin-top: 137%;
            font-size: 12px;
            font-weight: bold;
            color: black;
            margin-left: -40%;
          }
          .em {
            position: fixed;
            margin-top: 142.5%;
            height: 5%;
            width: 85%;
            margin-left: -77%;
            font-size: 10px;
          }
          #psswrd {
            position: fixed;
            margin-top: 156%;
            height: 5%;
            width: 40%;
            margin-left: -77%;
            font-size: 10px;
          }
          .btn {
            position: absolute;
            margin-top: 156%;
            margin-left: -23.6%;
            font-size: 10px;
            button{
              text-decoration: none;
  max-width: auto;
  background-color:  #c0924c;
  color: rgb(255 255 255);
  padding: .9rem 2rem;
  font-weight:bold;
  font-family:italic;
  border: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing:5px;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;

  &:hover,
  &:active {
    box-shadow: 0 2rem 2rem 0 rgb(132 144 255 / 30%);
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: scale(0.96);
  }

  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
            }
          }
        }
      }
    }
  }
`;

const BgImg = styled.div`
  mix-blend-mode: darken;
  opacity: 0.5;
  margin-left: 25%;
  margin-top: 11rem;
  width: 50%;
  position: fixed;
`;

export default Cart;
