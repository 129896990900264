import styled from "styled-components";
import SliderHome from "./Slider";
import Under5000Products from "./components/Under5000Products";
import img from "./components/rk__monogram.png"
import ImgCat from "./components/ImgCat";

const Under5000 = () => {
  return (
    <>
  
      <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <Under5000Products />
     
  
    </>
  );
};

const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:15rem;
width:50%;
position:fixed;

`
export default Under5000;
