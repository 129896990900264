import React, { useState } from "react";
import FormatPrice from "../Helpers/FormatPrice";
import CartAmountToggle from "./CartAmountToggle";
import { FaTrash } from "react-icons/fa";
import { useCartContext } from "../context/cart_context";

const CartItem2 = ({ id, name, image, color, price, amount }) => {
  const { removeItem, setDecrease, setIncrement } = useCartContext();
  const { cart, clearCart, total_price, shipping_fee } = useCartContext();


  return (
    <p>{id.slice(0, 3)}*{amount}.</p>
  );
};

export default CartItem2;