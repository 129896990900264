import styled from "styled-components";
import SliderHome from "./Slider";
import HanumanProduct from "./components/HanumanProducts";
import img from "./components/rk__monogram.png"
import ImgCat from "./components/ImgCat";

const Hanuman = () => {

  return (
    <>
  
      <BgImg>
        <img src={img} alt=""/>

      </BgImg>
      <ImgCat/>
      <HanumanProduct />
     
  
    </>
  );
};


const BgImg = styled.div`
mix-blend-mode: darken;
opacity:0.5;
margin-left:25%;
margin-top:15rem;
width:50%;
position:fixed;

`

export default Hanuman;
